<div>
  <p-sidebar class="send-email" [(visible)]="sendemailslider" [dismissible]="false" [modal]="true"
    (onHide)="closeSidebar()" position="right" [style]="{width: '70vw'}">
    <ng-template pTemplate="header">
      <div class="send-email-header">
        <span class="send-email-text">Send Message</span>
        <div class="header-btn" [ngClass]="{'disabled': !showSendEmail}">
          <app-button [btnProperties]="btnProperties['sendEmail']" (buttonClick)="sendMessage()"></app-button>
        </div>
      </div>
      <p-divider layout="horizontal" class="p-divider-width"></p-divider>
    </ng-template>

    <div class="error-title-box" *ngIf="isAllNotificationDisabled()">
        <span>
          Notification delivery via [Push Notifications/Email] is currently disabled in platform settings. Kindly enable the same to use it.
        </span>
    </div>
    <span *ngIf="!isAllNotificationDisabled()">
      <form class="page-grid" [formGroup]="sendEmailForm">
        <div class="p-float-label">
          <p-dropdown [options]="messageTypeOptions" placeholder="Select Message Type" formControlName="messageType"
            optionLabel="label" (onChange)="onMessageTypeChange($event)" optionValue="value">
          </p-dropdown>
          <label for="messageType">Message Type <span class="imp">*</span></label>
        </div>
        <div *ngIf="showForm">
          <div class="student-count-container"
            *ngIf="selectedMessageType &&  (selectAllStudentCount || studentDataList.length)">
            <label for="text-header">To</label>
            <div class="text-body">
              {{ selectAllStudentCount || studentDataList.length }} Student{{ (selectAllStudentCount ||
              studentDataList.length) > 1 ? 's' : '' }}
            </div>
          </div>
          <div class="p-float-label" *ngIf="selectedMessageType && selectedMessageType==='email'">
            <p-dropdown formControlName="template" [options]="templatesList" optionLabel="template_title"
              [autoDisplayFirst]="false" (onChange)="onTemplateChange($event)" optionValue="id">
            </p-dropdown>
            <label for="inputtext">Email Template <span class="imp">*</span></label>
            <!-- <span class="field-error" *ngIf="clicked">This field is required!</span> -->
            <app-handle-error [clicked]="clicked" [control]="sendEmailForm.get('template')" name="Template">
            </app-handle-error>
          </div>
          <div class="p-float-label" *ngIf="selectedMessageType && selectedMessageType==='email'">
            <input type="email" style="width: 25vw;" name="from" class="inputtext" pInputText formControlName="from"
              readonly>
            <label for="inputtext">From <span class="imp">*</span></label>
            <app-handle-error [clicked]="clicked" [control]="sendEmailForm.get('from')" name="From">
            </app-handle-error>
          </div>
          <div class="p-float-label" *ngIf="selectedMessageType && selectedMessageType==='pushNotification'">
            <p-dropdown formControlName="category" [options]="messageCategoryOptions"
              placeholder="Select Notification type">
            </p-dropdown>
            <label for="category">Notification Type<span class="imp">*</span></label>
          </div>
          <div class="card flex justify-content-center p-float-label flex-column"
            *ngIf="selectedMessageType && (!selectAllStudentCount && !studentDataList.length)">
            <p-chips formControlName="emails" separator="\n" (paste)="handlePaste($event)" (keydown)="handleKeydown($event)"
              placeholder="Type email and press enter to add" (onRemove)="handleRemove($event)"
              [ngClass]="{'disabled': showSendEmail}">
              <ng-template let-email let-index pTemplate="item">
                <div [ngClass]="{'invalid-email': isInvalidEmail(email)}">
                  {{ email }}
                </div>
              </ng-template>
            </p-chips>
            <label for="{{studentsEnum.EMAILIDS}}">{{studentsEnum.EMAILIDS}}
              <span class="imp">*</span>
            </label>
            <app-handle-error [clicked]="clicked" [control]="sendEmailForm.get('emails')" name="Email">
            </app-handle-error>
          </div>
          <div class="flex align-center"
            *ngIf="selectedMessageType && sendEmailForm.get('emails')?.value?.length && !roundBasedEmail">
            <div>
              <u><span class="apply-filters" (click)="validateEmail()">Validate Emails</span></u>
            </div>
          </div>
          <div class="p-float-label" *ngIf="selectedMessageType === 'email'">
            <p-multiSelect id="ccEmails" [options]="adminEmailOptions" formControlName="ccEmails"
              placeholder="Select Admins to CC" [filter]="true" [showToggleAll]="false"
              (onChange)="onCcEmailChange($event.value)">
    
              <ng-template pTemplate="header">
                <div class="select-all-container">
                  <input type="checkbox" [checked]="selectAllChecked" (change)="toggleSelectAll($event)">
                </div>
              </ng-template>
    
            </p-multiSelect>
            <label for="ccEmails">CC Admin Emails</label>
          </div>
          <div class="notes" *ngIf="selectedMessageType && (duplicates.length > 0)">
            <div>
              <div class="pageInfo">
                <label class="headings" [style]="{
                                            color: 'rgb(211, 47, 47)',
                                            fontWeight: 'bold',
                                            fontSize: '14px'
                                        }">Error :
                  <span *ngIf="duplicates.length > 0" class="total-mark"
                    [style]="{ fontWeight: 'lighter', color: 'rgb(211, 47, 47)' }">
                    Duplicate emails found.
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div class="p-float-label" *ngIf="selectedMessageType">
            <input type="text" style="width: 25vw;" name="" class="inputtext" pInputText formControlName="subjectTitle">
            <label for="inputtext">Subject <span class="imp">*</span></label>
            <app-handle-error [clicked]="clicked" [control]="sendEmailForm.get('subjectTitle')" name="Subject">
            </app-handle-error>
          </div>
          <div *ngIf="selectedMessageType && selectedMessageType==='email'">
            <span>Attachments:</span>
            <span class="templateForm-elements">
              <ngx-dropzone class="file-dropzone" (change)="fileUpload($event)">
                <ngx-dropzone-label *ngIf="!isFileUploading">
                  <div class="dropzone-label dropzone-label-small">
                    <div class="dropzone-text">
                      <em class="pi pi-cloud-upload"></em>
                      <p class="upload-text">
                        Click here to
                        <span class="text-color">upload files</span>,
                        or drag and drop
                        <strong>multiple files</strong>
                      </p>
                    </div>
                  </div>
                </ngx-dropzone-label>
                <div *ngIf="isFileUploading">
                  <p-progressSpinner class="spinner-size" strokeWidth="7" animationDuration="0.5s">
                  </p-progressSpinner>
                </div>
              </ngx-dropzone>
              <div *ngFor="let attachment of sendEmailForm.get('attachment')?.value;let idx = index">
                <div class="uploaded-container">
                  <div class="center-content">
                    <div class="file-icon backgroung-color-change">
                      <p [ngClass]="globalService.getIconClass(attachment.filePath)" class="pi template-file-icon"></p>
                    </div>
                    <div class="cohort-file_info" (click)="openLink(attachment.filePath)">
                      <p class="file-name"> {{ attachment.filePath | extractFilename: 35 }} </p>
                    </div>
                    <div class="cancel" (click)="removeFile(idx)">
                      <p class="file-cancel-icon">
                        <em class="report-download pi pi-times"></em>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </span>
          </div>
          <div *ngIf="selectedMessageType">
            <span>Content:
              <span class="imp">*</span>
            </span>
            <div style="margin: 5px 0;">
              <textarea id="content"></textarea>
            </div>
          </div>
        </div>
      </form>
    </span>
  </p-sidebar>
</div>