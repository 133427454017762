export enum Eglobal{
    VIEW = 'View',
    BTNCANCEL = 'Cancel',
    BTNAPPLY = 'Apply',
    MAPPROGRAMME = 'Map Programme',
    INVITE = 'Invite',
    UPLOAD = 'Upload',
    EDITFIELD = 'Edit Field',
    UPDATE = 'Update',
    SAVEANDNEXT = 'Save & Next',
    CALENDAR = 'Calendar View',
    LIST = 'List View',  
    VIEWSTUDENT = 'View Students', 
    STATUS= 'Status',
    ACADEMICYEAR = 'Pass out year',
    CREATEDRIVE = 'Create',
    SALARY  = 'Salary',
    STIPEND = 'Stipend',
    SAVE = 'Save',
    VIEWPROFILE = 'View Profile'
}

export enum Breadcrumb {
    JOBS = 'Jobs',
    REMINDERS = 'Drives Reminder',
    STUDENTS = 'Manage Students',
    ELIGIBILITY = 'Eligibility',
    ELIGIBILITYCHECK = 'Eligibility Check',
    EMAILTEMPLATE = 'Email Template',
    DRIVES = 'Drives',
    PROGRAMMEMAPPING = 'Programme Mapping',
    REPORTS = 'Reports',
    SETTINGS = 'Settings',
    CHANGEREQUEST = 'Change Request',
    DASHBOARD = 'Dashboard',
    REPUBLISHQUEUE = 'Republish Queue',
    DRIVE_PLANNER = 'Drive Planner',
    EMAIL_INSIGHTS = 'Email Insights',
    ENGAGEMENT_METRICS = 'Engagement Metrics',
    CHAT = 'Chat'
}

export const GBtnProperties = {
    applyFilter: {
        btnName: 'Apply Filter',
        fontColor: 'var(--clr-primary)',
        background: 'var(--clr-white)',
        border: '1px solid var(--clr-primary)',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px',
        height: '100%',
        borderRadius: '4px',
        marginleft: '0px',
        padding: '0px 10px',
        cursor: 'pointer'
    },
    cancelBtn: {
        btnName: 'Cancel',
        fontColor: 'var(--clr-primary)',
        background: 'var(--clr-white)',
        border: '1px solid var(--clr-primary)',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px',
        height: '100%',
        borderRadius: '4px',
        marginleft: '0px',
        padding: '0px 10px',
        cursor: 'pointer'
    }
}

export enum TableDefaults {
    Student = 'student-table',
    Placement = 'placement-table',
    Internship = 'internship-table',
    Eligibility = 'eligibility-table',
}

export enum MarkTypeToPercentageCGPA {
    tenth_mark = 'tenth_percentage_CGPA',
    twelfth_mark = 'twelfth_percentage_CGPA',
    ug_mark = 'ug_percentage_CGPA',
    pg_mark = 'pg_percentage_CGPA',
    diploma_mark = 'diploma_percentage_CGPA',
}

export enum PlatformStatus {
    DISABLED = 'Disabled',
    ENABLED ='Enabled'
}

export enum ProfileStatus {
    INVITED = 'INVITED',
    JOINED = 'JOINED',
    PUBLISHED = 'PUBLISHED',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
}

export enum PlacementInterest {
    No = 'No',
    Yes = 'Yes'
}

export enum EnumDriveStatus {
    INACTIVE = 'INACTIVE',
    ACTIVE = 'ACTIVE',
}