import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';
import { Breadcrumb } from '@enum';
const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => import('../app/module/components/login/login.module').then(m => m.LoginModule),
    canActivate: [],
  },
  {
    path: 'forgot',
    loadChildren: () => import('../app/module/components/login/login.module').then(m => m.LoginModule),
    canActivate: [],
  },
  {
    path: 'students',
    loadChildren: () => import('../app/module/components/students/students.module').then(m => m.StudentsModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: [
        { label: Breadcrumb.STUDENTS, url: '' },
      ]
    }
  },
  {
    path: 'students/profile',
    loadChildren: () => import('../app/module/components/student-profile/student-profile.module').then(m => m.StudentProfileModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: [
        { label: Breadcrumb.STUDENTS, url: '/students' },
        { label: "email name", url: '' }
      ]
    }
  },
  {
    path: 'students/change-request',
    loadChildren: () => import('../app/module/components/change-request/change-request.module').then(m => m.ChangeRequestModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: [
        { label: Breadcrumb.STUDENTS, url: '/students' },
        { label: Breadcrumb.CHANGEREQUEST, url: '' }
      ]
    }
  },
  {
    path: 'settings',
    loadChildren: () => import('../app/module/components/settings/settings.module').then(m => m.SettingsModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: [
        { label: Breadcrumb.SETTINGS, url: '' },
      ]
    }
  },
  {
    path: 'reminders',
    loadChildren: () => import('../app/module/components/reminders/reminders.module').then(m => m.RemindersModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: [
        { label: Breadcrumb.REMINDERS, url: '' }
      ]
    }
  },
  {
    path: 'chat',
    loadChildren: () => import('../app/module/components/chat/chat.module').then(m => m.ChatModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: [
        { label: Breadcrumb.CHAT, url: '' }
      ]
    }
  },
  {
    path: 'dashboard',
    loadChildren: () => import('../app/module/components/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: [
        { label: Breadcrumb.DASHBOARD, url: '' }
      ]
    }
  },
  {
    path: 'eligibility',
    loadChildren: () => import('../app/module/components/eligibility-criteria/eligibility-criteria.module').then(m => m.EligibilityCriteriaModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: [
        { label: Breadcrumb.ELIGIBILITY, url: '' },
      ]
    }
  },
  {
    path: 'republish-queue',
    loadChildren: () => import('./module/components/republish-queue/republish-queue.module').then(m => m.RepublishQueueModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: [
        { label: Breadcrumb.REPUBLISHQUEUE, url: '' },
      ]
    }
  },
  {
    path: 'programme-mapping',
    loadChildren: () => import('../app/module/components/programme-offered/programme-offered.module').then(m => m.ProgrammeOfferedModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: [
        { label: Breadcrumb.PROGRAMMEMAPPING, url: '' },
      ]
    }
  },
  {
    path: 'email-template',
    loadChildren: () => import('../app/module/components/email-template/email-template.module').then(m => m.EmailTemplateModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: [
        { label: Breadcrumb.EMAILTEMPLATE, url: '' },
      ]
    }
  },
  {
    path: 'email-insights',
    loadChildren: () => import('./module/components/message-insights/message-insights.module').then(m => m.MessageInsightsModule),
    canActivate: [AuthGuard],
    data: { 
      breadcrumb: [
        { label: Breadcrumb.EMAIL_INSIGHTS, url: '' },
      ] 
    }
  },
  {
    path: 'engagement-metrics',
    loadChildren: () => import('./module/components/engagement-metrics/engagement-metrics.module').then(m => m.EngagementMetricsModule),
    canActivate: [AuthGuard],
    data: { 
      breadcrumb: [
        { label: Breadcrumb.ENGAGEMENT_METRICS, url: '' },
      ] 
    }
  },
  {
    path: 'planner',
    loadChildren: () => import('../app/module/components/drive-planner/drive-planner.module').then(m => m.DrivePlannerModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: [
        { label: Breadcrumb.DRIVE_PLANNER, url: '' },
      ]
    }
  },
  {
    path: 'drives',
    loadChildren: () => import('../app/module/components/drives/drives.module').then(m => m.DrivesModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: [
        { label: Breadcrumb.DRIVES, url: '' },
      ]
    }
  },
  {
    path: 'drives/drive-details',
    loadChildren: () => import('../app/module/components/drive-details/drive-details.module').then(m => m.DriveDetailsModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: [
        { label: Breadcrumb.DRIVES, url: '/drives' },
        { label: 'Drive name', url: '' }
      ]
    }
  },
  {
    path: 'jobs',
    loadChildren: () => import('../app/module/components/drives/drives.module').then(m => m.DrivesModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: [
        { label: Breadcrumb.JOBS, url: '' },
      ]
    }
  },
  {
    path: 'jobs/job-details',
    loadChildren: () => import('../app/module/components/drive-details/drive-details.module').then(m => m.DriveDetailsModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: [
        { label: Breadcrumb.JOBS, url: '/jobs' },
        { label: 'Job name', url: '' }
      ]
    }
  },
  {
    path: 'redirect',
    loadChildren: () => import('../app/module/components/redirect/redirect.module').then(m => m.RedirectModule),
    canActivate: [],
  },
  {
    path: 'student-resume',
    loadChildren: () => import('../app/module/components/resume-viewer/resume-viewer.module').then(m => m.ResumeViewerModule),
    canActivate: [],
  },
  {
    path: 'reports',
    loadChildren: () => import('../app/module/components/reports/reports.module').then(m => m.ReportsModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: [
        { label: Breadcrumb.REPORTS, url: '' },
      ]
    }
  },
  { path: '**', redirectTo: 'planner', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
