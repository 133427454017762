<div *ngIf="isLoading" class="{{(isLoading) ? 'loadingOverlay' : ''}}">
    <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
    </p-progressSpinner>
</div>

<div class="drive-body">
    <div *ngIf="!createDrive" class="drive-header-top">
        <div class="drive-tabs">
            <app-tab [tabs]="createOptions" (tabChange)="createTabChange($event)"></app-tab>
            <div>
                <span class="drive-lables" [ngClass]="{'saved-label': originalDriveInfo.driveStatus === patDriveStatus.saved, 
                                                    'published-label': originalDriveInfo.driveStatus === patDriveStatus.upcoming,
                                                    'onHold-label': originalDriveInfo.driveStatus === patDriveStatus.hold,
                                                    'ended-label': originalDriveInfo.driveStatus === patDriveStatus.completed,
                                                    'active-label': originalDriveInfo.driveStatus === patDriveStatus.ongoing
                                                    }">
                    {{ originalDriveInfo.driveStatus }}
                </span>
            </div>
        </div>
        <div class="drive-buttons">
            <div class="drive-buttons">
                <ng-container *ngIf="originalDriveInfo.driveStatus !== patDriveStatus.completed">
                    <ng-container *ngIf="originalDriveInfo.driveStatus === patDriveStatus.hold; else driveBtns">
                        <app-button [btnProperties]="btnProperties['undoOnHold']"
                            (buttonClick)="onHoldDrive(false)"></app-button>
                    </ng-container>
                    <ng-template #driveBtns>
                        <app-button *ngIf="currentTab === 'details'" [btnProperties]="btnProperties['saveAndProceed']"
                            (buttonClick)="saveActions()"></app-button>
                        <app-button *ngIf="currentTab === 'emailTemplate'"
                            [btnProperties]="btnProperties['emailTemplate']" (buttonClick)="saveActions()"></app-button>
                        <ng-container *ngIf="currentTab === 'eligibility'">
                            <app-button
                                *ngIf="originalDriveInfo.driveStatus === patDriveStatus.upcoming || originalDriveInfo.driveStatus == patDriveStatus.ongoing"
                                [btnProperties]="btnProperties['onHoldDrive']"
                                (buttonClick)="onHoldConfirm()"></app-button>
                            <app-button *ngIf="originalDriveInfo.driveStatus === patDriveStatus.upcoming || originalDriveInfo.driveStatus == patDriveStatus.ongoing ||
                                        originalDriveInfo.driveStatus === 'Active'"
                                [btnProperties]="btnProperties['rePublishDrive']"
                                (buttonClick)="republishDrive()"></app-button>
                            <app-button *ngIf="originalDriveInfo.driveStatus === patDriveStatus.saved"
                                [btnProperties]="btnProperties['saveDriveEligibility']"
                                (buttonClick)="saveEligibility()"></app-button>
                            <app-button *ngIf="originalDriveInfo.driveStatus === patDriveStatus.saved"
                                [btnProperties]="btnProperties['publishDrive']"
                                (buttonClick)="saveActions()"></app-button>
                        </ng-container>
                        <app-button *ngIf="(currentTab === 'interviewProcess' || currentTab === 'offerConfirmation')"
                            [btnProperties]="btnProperties['endDrive']" (buttonClick)="endDialogConfirm()"></app-button>
                    </ng-template>
                </ng-container>
            </div>
        </div>
    </div>

    <!-- <div class="body-top-pattern" *ngIf="!createDrive" [ngStyle]="{'background-image': 'url(' + drivePattern + ')'}">
        <ng-container *ngIf="!patternLoading">
            <div class="body-top-pattern-container">
                <span class="drive-name"> {{ originalDriveInfo.driveName }} </span>
                <div class="body-top-pattern-btn">
                    
                </div>
            </div>
            <div>
                <span></span>
            </div>
        </ng-container>
    </div> -->
    <div class="header-body-all" [ngClass]="{
        'header-body-all-drive': createDrive,
        'header-body-all-edit': !createDrive
    }">
        <div *ngIf="currentTab == 'details'" class="create-details"
            [ngClass]="{'createDrive': createDrive,'editDrive':!createDrive}">
            <div class="create-info">
                <div class="create-background">
                    <ng-container *ngFor="let info of createDriveOptions;let i = index">
                        <div class="info-list" [ngClass]="{'selectedInfo': info.isActive}"
                            (click)="changeInfo(info , i)">
                            <span>{{info.label}}</span>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="create-body1" #accordion0>
                <p-accordion (onClose)="closeAccordian($event)" (onOpen)="openAccordian($event)"
                    [activeIndex]="activeIndex" [multiple]="true">
                    <ng-container #createDrive>
                        <p-accordionTab header="Company Information">
                            <form class="drive-info-create gap-0" [formGroup]="createDriveInfo">
                                <span class="p-float-label templateForm-elements">
                                    <!-- <p-dropdown class="dropdown" inputId="dropdown" optionLabel="company_name"
                                        [options]="companies" [filter]="true" formControlName="company"
                                        [required]="true" (onChange)="companyData($event)" [autoDisplayFirst]="false"
                                        [styleClass]="clicked && createDriveInfo.controls['company'].errors ? 'invalid' : ''">
                                    </p-dropdown> -->
                                    <p-dropdown class="dropdown" inputId="dropdown" optionLabel="company_name" [options]="companies" [filter]="true"
                                        formControlName="company" [required]="true" (onChange)="companyData($event)" [autoDisplayFirst]="false"
                                        (onFilter)="companySearchFilter($event)"
                                        [styleClass]="clicked && createDriveInfo.controls['company'].errors ? 'invalid' : ''">
                                    </p-dropdown>
                                    <label for="dropdown">Select a Company<span class="imp">*</span></label>
                                    <app-handle-error [clicked]="clicked" [control]="createDriveInfo.get('company')"
                                        name="Company">
                                    </app-handle-error>
                                </span>
                                <span class="p-float-label templateForm-elements">
                                    <input type="text" class="inputtext" id="inputtext" pInputText
                                        formControlName="companyUrl"
                                        [ngClass]="{'invalid': clicked && createDriveInfo.controls['companyUrl'].errors}">
                                    <label for="template-title">Company Domain URL</label>
                                    <app-handle-error [clicked]="clicked" [control]="createDriveInfo.get('companyUrl')"
                                        name="Company Domain URL">
                                    </app-handle-error>
                                </span>
                                <span class="p-float-label templateForm-elements">
                                    <p-dropdown class="dropdown" inputId="dropdown" optionLabel="company_category"
                                        placeholder="Select company category" [options]="company_categories"
                                        formControlName="companyCategory" [required]="true"
                                        [styleClass]="clicked && createDriveInfo.controls['companyCategory'].errors ? 'invalid' : ''">
                                    </p-dropdown>
                                    <label for="dropdown">{{ categoryLabel }}<span class="imp">*</span></label>
                                    <app-handle-error [clicked]="clicked"
                                        [control]="createDriveInfo.get('companyCategory')" name="Category">
                                    </app-handle-error>
                                </span>
                                <span class="p-float-label templateForm-elements">
                                    <p-dropdown class="dropdown" inputId="dropdown" optionLabel="placement_category"
                                        placeholder="Select placement category" [options]="placement_categories"
                                        formControlName="placementCategory" [required]="true"
                                        [styleClass]="clicked && createDriveInfo.controls['placementCategory'].errors ? 'invalid' : ''">
                                    </p-dropdown>
                                    <label for="dropdown">Placement Category<span class="imp">*</span></label>
                                    <app-handle-error [clicked]="clicked"
                                        [control]="createDriveInfo.get('placementCategory')" name="Placement Category">
                                    </app-handle-error>
                                </span>
                                <span class="p-float-label templateForm-elements">
                                    <p-chips class="chips-form" [allowDuplicate]="false"
                                        placeholder="Type text and press 'Enter' to add"
                                        formControlName="company_location"></p-chips>
                                    <label for="template-title">Location<span
                                            [style]="{color: '#E24A47'}">*</span></label>
                                    <app-handle-error [clicked]="clicked"
                                        [control]="createDriveInfo.get('company_location')" name="Location">
                                    </app-handle-error>
                                </span>
                            </form>
                        </p-accordionTab>
                        <p-accordionTab header="{{'Drive Information'  | driveLabelPipe}}">
                            <form class="drive-info-create gap-0" [formGroup]="createDriveInfo">
                                <span class="p-float-label templateForm-elements">
                                    <input type="text" class="inputtext" id="inputtext" pInputText
                                        formControlName="driveName"
                                        [ngClass]="{'invalid': clicked && createDriveInfo.controls['driveName'].errors}">
                                    <label for="template-title">{{'Drive Name' | driveLabelPipe}}<span
                                            [style]="{color: '#E24A47'}">*</span></label>
                                    <app-handle-error [clicked]="clicked" [control]="createDriveInfo.get('driveName')"
                                        name="{{label_singular}} Name">
                                    </app-handle-error>
                                </span>
                                <span class="p-float-label templateForm-elements">
                                    <p-multiSelect class="dropdown" inputId="dropdown" optionLabel="job_type"
                                        optionValue="job_type" [options]="driveTypes" formControlName="driveType"
                                        [required]="true" placeholder="Select Drive Type"
                                        [styleClass]="clicked && createDriveInfo.controls['driveType'].errors ? 'invalid' : ''"></p-multiSelect>
                                    <label for="dropdown">{{'Drive Type' | driveLabelPipe}}<span
                                            class="imp">*</span></label>
                                    <app-handle-error [clicked]="clicked" [control]="createDriveInfo.get('driveType')"
                                        name="{{label_singular}} Type">
                                    </app-handle-error>
                                </span>
                                <span class="templateForm-elements">
                                    <label class="file-label"> {{ 'Drive Description' | driveLabelPipe }} </label>
                                    <div class="drive-editor-container">
                                        <p-editor formControlName="description"
                                            [style]="{ 'min-height': '20vh', width: '80%' }" [modules]="editorConfig"
                                            [placeholder]="defaultEditorPlaceholder">
                                        </p-editor>
                                    </div>
                                </span>
                                <span class="templateForm-elements">
                                    <label class="file-label"> {{ 'Drive Attachments' | driveLabelPipe }} </label>
                                    <ngx-dropzone class="file-dropzone" (change)="onFileUpload($event)">
                                        <ngx-dropzone-label *ngIf="!isFileUploading">
                                            <div class="dropzone-label dropzone-label-small">
                                                <div class="dropzone-text">
                                                    <em class="pi pi-cloud-upload"></em>
                                                    <p class="upload-text">
                                                        Click here to
                                                        <span class="text-color">upload files</span>,
                                                        or drag and drop
                                                        <strong>multiple files</strong>
                                                    </p>
                                                </div>
                                            </div>
                                        </ngx-dropzone-label>
                                        <div *ngIf="isFileUploading">
                                            <p-progressSpinner class="spinner-size" strokeWidth="7"
                                                animationDuration="0.5s">
                                            </p-progressSpinner>
                                        </div>
                                    </ngx-dropzone>
                                    <div
                                        *ngFor="let attachment of createDriveInfo.get('drive_attachments')?.value;let idx = index">
                                        <div class="uploaded-container">
                                            <div class="center-content">
                                                <div class="file-icon backgroung-color-change">
                                                    <p [ngClass]="globalService.getIconClass(attachment)"
                                                        class="pi template-file-icon"></p>
                                                </div>
                                                <div class="cohort-file_info" (click)="openLink(attachment)">
                                                    <p class="file-name"> {{ attachment | extractFilename: 35 }} </p>
                                                </div>
                                                <div class="cancel" (click)="removeFile(idx)">
                                                    <p class="file-cancel-icon">
                                                        <em class="report-download pi pi-times"></em>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </span>
                                <span class="p-float-label templateForm-elements">
                                    <input type="text" class="inputtext" id="inputtext" pInputText
                                        formControlName="driveSpoc"
                                        [ngClass]="{'invalid': clicked && createDriveInfo.controls['driveSpoc'].errors}">
                                    <label for="template-title">{{'Drive SPOC' | driveLabelPipe}}</label>
                                    <app-handle-error [clicked]="clicked" [control]="createDriveInfo.get('driveSpoc')"
                                        name="{{label_singular}} SPOC">
                                    </app-handle-error>
                                </span>
                                <div class="profile-range" *ngIf="driveObjective === 'Internship'">
                                    <span class="p-float-label">
                                        <p-dropdown class="dropdown" inputId="dropdown" placeholder="Select duration"
                                            [options]="internshipDuration" formControlName="internshipDuration"
                                            [required]="true"
                                            [styleClass]="clicked && createDriveInfo.controls['internshipDuration'].errors ? 'invalid' : ''">
                                        </p-dropdown>
                                        <label for="template-title">Internship Duration<span
                                                [style]="{color: '#E24A47'}">*</span></label>
                                        <app-handle-error [clicked]="clicked"
                                            [control]="createDriveInfo.get('internshipDuration')" name="Fixed">
                                        </app-handle-error>
                                    </span>
                                    <span class="p-float-label">
                                        <p-dropdown class="dropdown" inputId="dropdown" placeholder="Select timeline"
                                            [options]="internshipTimeLine" formControlName="internshipTimeLine"
                                            [required]="true"
                                            [styleClass]="clicked && createDriveInfo.controls['internshipTimeLine'].errors ? 'invalid' : ''">
                                        </p-dropdown>
                                        <label for="template-title">Week(s)/Month(s)<span
                                                [style]="{color: '#E24A47'}">*</span></label>
                                        <app-handle-error [clicked]="clicked"
                                            [control]="createDriveInfo.get('internshipTimeLine')"
                                            name="Week(s)/Month(s)">
                                        </app-handle-error>
                                    </span>
                                </div>
                            </form>
                        </p-accordionTab>
                        <p-accordionTab header="{{'Profile & Salary Information' | driveLabelPipe}}">
                            <form class="drive-info-create" [formGroup]="createDriveInfo">
                                <div class="p-float-label templateForm-elements">
                                    <ng-container
                                        *ngIf="createDriveInfo.get('profileType')?.value === 'single'; else multipleProfiles">
                                        <!-- For 'single' profile type, max set to 1 -->
                                        <p-chips class="chips-form" [allowDuplicate]="false" [max]="1"
                                            placeholder="Type text and press 'Enter' to add"
                                            formControlName="profileDesignation" (onAdd)="onChangeDesignation($event)"
                                            (onRemove)="onRemoveDesignation($event)"></p-chips>
                                    </ng-container>
                                    <ng-template #multipleProfiles>
                                        <!-- For other profile types, no max limit -->
                                        <p-chips class="chips-form" [allowDuplicate]="false"
                                            placeholder="Type text and press 'Enter' to add"
                                            formControlName="profileDesignation" (onAdd)="onChangeDesignation($event)"
                                            (onRemove)="onRemoveDesignation($event)"></p-chips>
                                    </ng-template>
                                    <label for="template-title">Profile/Designation <span class="imp">*</span></label>
                                    <app-handle-error [clicked]="clicked"
                                        [control]="createDriveInfo.get('profileDesignation')"
                                        name="Profile/Designation">
                                    </app-handle-error>
                                </div>
                                <ng-container *ngIf="designationBoolean">
                                    <div class="profile-designation">
                                        <div class="profile-permissions">
                                            <div class="profile-degree"
                                                *ngIf="createDriveInfo.get('profileType')?.value === 'multiple'">
                                                <p-checkbox formControlName="allowToSelectProfile" [binary]="true"
                                                    (onChange)="allowStdInterestedProfile()"
                                                    [disabled]="preferenceDisabled"></p-checkbox>
                                                <span>{{driveEnum.ALLOW}}</span>
                                            </div>
                                            <div class="create-fields profile-degree"
                                                *ngIf="createDriveInfo.get('allowToSelectProfile')?.value">
                                                <p-checkbox formControlName="allow_priority"
                                                    [binary]="true"></p-checkbox>
                                                <span>Enable Priority</span>
                                            </div>
                                            <div class="p-float-label create-fields"
                                                *ngIf="createDriveInfo.get('allowToSelectProfile')?.value">
                                                <p-dropdown formControlName="preference_count"
                                                    [autoDisplayFirst]="false"
                                                    [options]="preferenceOption"></p-dropdown>
                                                <label for="inputtext">Maximum Count<span class="imp">*</span></label>
                                            </div>
                                            <div class="profile-degree"
                                                *ngIf="createDriveInfo.get('profileType')?.value === 'multiple'">
                                                <p-checkbox formControlName="profileBased" [binary]="true"
                                                    (onChange)="onChangeProfileBased($event)"></p-checkbox>
                                                <span>{{driveEnum.PROFILEBASED}}</span>
                                            </div>
                                            <div class="profile-degree">
                                                <p-checkbox formControlName="degree" [binary]="true"
                                                    (onChange)="onChangeDegreeBased($event)"></p-checkbox>
                                                <span>{{driveEnum.DEGREEBASED}}</span>
                                            </div>
                                        </div>
                                        <ng-container
                                            *ngFor="let profileKey of objectKeys(profileFormGroups); let i = index">
                                            <div class="profile-list">
                                                <ng-container
                                                    *ngFor="let formGroup of profileFormGroups[profileKey]; let j = index">
                                                    <form [formGroup]="formGroup" class="profile-form">
                                                        <div class="profile-header justify-between">
                                                            <span>{{formGroup.value.profile || 'Profile'}}</span>
                                                            <div class="flex gap-10">
                                                                <span onKeyPress
                                                                    *ngIf="createDriveInfo.get('degree')?.value && j === 0"
                                                                    (click)="addCloneForProfileDesignation(profileKey,formGroup.value.profile)"
                                                                    class="cursor text-underline add-clone">{{driveEnum.ADDNEWFIELD}}</span>
                                                                <span onKeyPress
                                                                    *ngIf="createDriveInfo.get('degree')?.value && j > 0"
                                                                    (click)="removeCloneForProfileDesignation(profileKey, j)"
                                                                    class="cursor text-underline remove-clone">{{driveEnum.REMOVEFIELD}}</span>
                                                                <div class="checkbox-type">
                                                                    <p-checkbox [formControlName]="'salary'"
                                                                        [binary]="true"
                                                                        (onChange)="onSalaryCheckbox($event, j, 'Salary', profileKey)"></p-checkbox>
                                                                    <span>{{globalEnum.SALARY | driveLabelPipe}}</span>
                                                                </div>
                                                                <div class="checkbox-type">
                                                                    <p-checkbox [formControlName]="'stipend'"
                                                                        [binary]="true"
                                                                        (onChange)="onSalaryCheckbox($event, j, 'Stipend', profileKey)"></p-checkbox>
                                                                    <span>{{globalEnum.STIPEND}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="profile-body">
                                                            <!-- for degree based salary -->
                                                            <ng-container *ngIf="createDriveInfo.get('degree')?.value">
                                                                <div
                                                                    class="card justify-content-center p-float-label margin-bottom-20 width50">
                                                                    <p-multiSelect
                                                                        [options]="studentDetailsCampusOptions"
                                                                        placeholder="Select Campus" optionLabel="label"
                                                                        formControlName="selectedCampus"
                                                                        optionValue="value"
                                                                        (onChange)="campus($event, formGroup, profileKey, j)"></p-multiSelect>
                                                                    <label for="educationalDetails">Campus<span
                                                                            class="imp">*</span></label>
                                                                    <app-handle-error [clicked]="clicked"
                                                                        [control]="createDriveInfo.get('selectedCampus')"
                                                                        name="Campus">
                                                                    </app-handle-error>
                                                                </div>
                                                                <div
                                                                    class="card justify-content-center p-float-label margin-bottom-20 width50">
                                                                    <p-multiSelect
                                                                        [options]="selectedDegreeOptions[profileKey + '-' + j]"
                                                                        [group]="true"
                                                                        placeholder="Select Degree & Specialization"
                                                                        formControlName="degree" optionValue="value"
                                                                        (onChange)="onchangeMultiselectDegree($event, profileKey, j)"
                                                                        optionLabel="label"
                                                                        [style]="{'cursor': !createDriveInfo.get('degree')?.value ? 'not-allowed' : ''}">
                                                                        <ng-template let-group pTemplate="group">
                                                                            <div class="flex align-items-center">
                                                                                <span>{{ group.label }}</span>
                                                                            </div>
                                                                        </ng-template>
                                                                    </p-multiSelect>

                                                                    <label for="educationalDetails">Degree &
                                                                        Specialization<span class="imp">*</span></label>
                                                                    <app-handle-error [clicked]="clicked"
                                                                        [control]="formGroup.get('degree')"
                                                                        name="Degree & Specialization">
                                                                    </app-handle-error>
                                                                </div>
                                                            </ng-container>
                                                            <!-- salary type -->
                                                            <ng-container *ngIf="formGroup.value.salary">
                                                                <div class="custom-divider dashed"
                                                                    *ngIf="createDriveInfo.get('degree')?.value"></div>
                                                                <div class="p-float-label create-fields">
                                                                    <p-dropdown formControlName="salaryBasis"
                                                                        [options]="salaryTypeOptions"
                                                                        optionLabel="label" optionValue="value"
                                                                        placeholder="{{'Salary' | driveLabelPipe}}"></p-dropdown>
                                                                    <label for="inputtext">{{'Salary' |
                                                                        driveLabelPipe}}<span
                                                                            class="imp">*</span></label>
                                                                </div>
                                                                <span>{{'Salary Type :' | driveLabelPipe}}</span>
                                                                <div class="radio-btns">
                                                                    <ng-container *ngFor="let option of salaryRange">
                                                                        <div class="btn-label">
                                                                            <div class="radio-button"
                                                                                [class.selected]="formGroup.value.salaryType === option.value"
                                                                                (click)="onSalaryTypeChange(option.value, formGroup, 'salaryType')">
                                                                                <div class="inner-circle"></div>
                                                                            </div>
                                                                            <span>{{ option.label }}</span>
                                                                        </div>
                                                                    </ng-container>
                                                                </div>
                                                                <div class="p-float-label create-fields profile-range">
                                                                    <ng-container
                                                                        *ngIf="formGroup.value.salaryType === 'fixed'">
                                                                        <span class="p-float-label">
                                                                            <input type="number" class="inputtext"
                                                                                id="inputtext" pInputText
                                                                                formControlName="fixedSalary"
                                                                                (input)="validateInput($event)"
                                                                                (keydown)="preventInvalidCharacters($event)"
                                                                                [ngClass]="{'invalid': clicked && formGroup.get('fixedSalary')?.value === ''}">
                                                                            <label for="template-title">Fixed<span
                                                                                    [style]="{color: '#E24A47'}">*</span></label>
                                                                            <app-handle-error [clicked]="clicked"
                                                                                [control]="formGroup.get('fixedSalary')"
                                                                                name="Fixed">
                                                                            </app-handle-error>
                                                                        </span>
                                                                    </ng-container>
                                                                    <ng-container
                                                                        *ngIf="formGroup.value.salaryType === 'range'">
                                                                        <div class="profile-range">
                                                                            <span class="p-float-label">
                                                                                <input type="number"
                                                                                    class="inputtext width100"
                                                                                    id="inputtext" pInputText
                                                                                    formControlName="fromRange"
                                                                                    (input)="validateInput($event)"
                                                                                    (keydown)="preventInvalidCharacters($event)"
                                                                                    [ngClass]="{'invalid': clicked && formGroup.get('fromRange')?.value === ''}">
                                                                                <label for="template-title">From<span
                                                                                        [style]="{color: '#E24A47'}">*</span></label>
                                                                                <app-handle-error [clicked]="clicked"
                                                                                    [control]="formGroup.get('fromRange')"
                                                                                    name="From">
                                                                                </app-handle-error>
                                                                            </span>
                                                                            <span class="p-float-label">
                                                                                <input type="number"
                                                                                    class="inputtext width100"
                                                                                    id="inputtext" pInputText
                                                                                    formControlName="toRange"
                                                                                    (input)="validateInput($event)"
                                                                                    (keydown)="preventInvalidCharacters($event)"
                                                                                    [ngClass]="{'invalid': clicked && formGroup.get('toRange')?.value === ''}">
                                                                                <label for="template-title">To<span
                                                                                        [style]="{color: '#E24A47'}">*</span></label>
                                                                                <app-handle-error [clicked]="clicked"
                                                                                    [control]="formGroup.get('toRange')"
                                                                                    name="To">
                                                                                </app-handle-error>
                                                                                <div class="table-relative">
                                                                                    <div class="title-error"
                                                                                        *ngIf="formGroup.hasError('rangeInvalid')">
                                                                                        <p>{{
                                                                                            formGroup.getError('message')
                                                                                            }}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </span>
                                                                        </div>
                                                                    </ng-container>
                                                                </div>
                                                                <div class="profile-degree"
                                                                    *ngIf="formGroup.value.salaryType === 'fixed'">
                                                                    <p-checkbox formControlName="salaryBreak"
                                                                        (onChange)="onSalaryBreakCheckbox($event,formGroup)"
                                                                        [binary]="true"></p-checkbox>
                                                                    <span>{{driveEnum.SALARYBREAK}}</span>
                                                                </div>
                                                                <div class="profile-range"
                                                                    *ngIf="formGroup.value.salaryBreak">
                                                                    <span class="p-float-label">
                                                                        <input type="number" class="inputtext width100"
                                                                            id="inputtext" pInputText
                                                                            formControlName="salaryFixed"
                                                                            (input)="validateInput($event)"
                                                                            (keydown)="preventInvalidCharacters($event)"
                                                                            [ngClass]="{'invalid': clicked && formGroup.value.salaryFixed === ''}">
                                                                        <label for="template-title">Fixed<span
                                                                                [style]="{color: '#E24A47'}">*</span></label>
                                                                        <app-handle-error [clicked]="clicked"
                                                                            [control]="formGroup.get('salaryFixed')"
                                                                            name="Fixed">
                                                                        </app-handle-error>
                                                                    </span>
                                                                    <span class="p-float-label">
                                                                        <input type="number" class="inputtext width100"
                                                                            id="inputtext" pInputText
                                                                            (input)="validateInput($event)"
                                                                            (keydown)="preventInvalidCharacters($event)"
                                                                            formControlName="salaryVariable"
                                                                            [ngClass]="{'invalid': clicked && formGroup.value.salaryVariable === ''}">
                                                                        <label for="template-title">Variable<span
                                                                                [style]="{color: '#E24A47'}">*</span></label>
                                                                        <app-handle-error [clicked]="clicked"
                                                                            [control]="formGroup.get('salaryVariable')"
                                                                            name="Variable">
                                                                        </app-handle-error>
                                                                    </span>
                                                                </div>
                                                            </ng-container>
                                                            <ng-container *ngIf="formGroup.value.stipend">
                                                                <div class="custom-divider dashed"
                                                                    *ngIf="formGroup.value.salary || createDriveInfo.get('degree')?.value">
                                                                </div>
                                                                <!-- stipend type -->
                                                                <div class="p-float-label create-fields">
                                                                    <p-dropdown formControlName="stipendBasis"
                                                                        [options]="salaryTypeOptions"
                                                                        optionLabel="label" optionValue="value"
                                                                        placeholder="Stipend"></p-dropdown>
                                                                    <label for="inputtext">Stipend<span
                                                                            class="imp">*</span></label>
                                                                </div>
                                                                <span>Stipend Type :</span>
                                                                <div class="radio-btns">
                                                                    <ng-container *ngFor="let option of stipendRange">
                                                                        <div class="btn-label">
                                                                            <div class="radio-button"
                                                                                [class.selected]="formGroup.value.stipendType === option.value"
                                                                                (click)="onSalaryTypeChange(option.value, formGroup, 'stipendType')">
                                                                                <div class="inner-circle"></div>
                                                                            </div>
                                                                            <span>{{ option.label }}</span>
                                                                        </div>
                                                                    </ng-container>
                                                                </div>
                                                                <div class="p-float-label create-fields">
                                                                    <ng-container>
                                                                        <span class="p-float-label"
                                                                            *ngIf="formGroup.value.stipendType == 'fixed'">
                                                                            <input type="number" class="inputtext"
                                                                                id="inputtext" pInputText
                                                                                (input)="validateInput($event)"
                                                                                (keydown)="preventInvalidCharacters($event)"
                                                                                formControlName="fixedStipend"
                                                                                [ngClass]="{'invalid': clicked && formGroup.value.fixedStipend === ''}">
                                                                            <label for="template-title">Fixed<span
                                                                                    [style]="{color: '#E24A47'}">*</span></label>
                                                                            <app-handle-error [clicked]="clicked"
                                                                                [control]="formGroup.get('fixedStipend')"
                                                                                name="Fixed">
                                                                            </app-handle-error>
                                                                        </span>
                                                                    </ng-container>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </form>
                                                </ng-container>
                                            </div>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </form>
                        </p-accordionTab>
                        <p-accordionTab header="Placement Information">
                            <form class="drive-info-create" [formGroup]="createDriveInfo">
                                <!-- <span style="margin-left: 3px;">Placement Date Status <span class="imp">*</span></span> -->
                                <!-- <div class="radio-btns">
                                    <ng-container *ngFor="let option of dateStatus">
                                        <div class="btn-label">
                                            <div class="radio-button"
                                                [class.selected]="dateStatusSelected === option.value"
                                                (click)="onDateStatusChange(option.value)">
                                                <div class="inner-circle"></div>
                                            </div>
                                            <span>{{ option.label }}</span>
                                        </div>
                                    </ng-container>
                                </div> -->
                                <!-- <div class="placement-date-container" *ngIf="dateStatusSelected != 'toBeAnnounced'">
                                    <span class="p-float-label  eventForm-elements">
                                        <p-calendar [(ngModel)]="placementStartDate"
                                            (onSelect)="resetPlacementEndDate(); checkDateClash()"
                                            [readonlyInput]="true" formControlName="placementDate" dateFormat="dd-mm-yy"
                                            dataType="date" id="inputtext" [minDate]="currentDate"
                                            class="configure-date-picker"
                                            [maxDate]="createDriveInfo.value?.placementEndDate"
                                            [showTime]="false" [hourFormat]="'12'" [showIcon]="true"
                                            [inputStyleClass]="clicked && createDriveInfo.controls['placementDate'].errors ? 'invalid' : ''">
                                        </p-calendar>
                                        <label for="inputtext">Placement Date<span class="imp">*</span></label>
                                        <app-handle-error [clicked]="clicked"
                                            [control]="createDriveInfo.get('placementDate')"
                                            name="Placement Date"></app-handle-error>
                                    </span>

                                    <div class="crash-label-container" *ngIf="dateClashDrives.length">
                                        <span class="drive-clash-label span-text-drive-clash">{{'Drive Date Clash Found'
                                            | driveLabelPipe}}</span>
                                        <span class="link-label span-text-drive-clash"
                                            (click)="openDriveClashDetails()">Click here to view</span>
                                    </div>
                                </div> -->


                                <span class="p-float-label  eventForm-elements">
                                    <p-calendar [(ngModel)]="lastDateToApply" [readonlyInput]="true"
                                        formControlName="lastDate" dateFormat="dd-mm-yy," dataType="date" id="inputtext"
                                        [minDate]="currentDate" class="configure-date-picker" [showIcon]="true"
                                        [showTime]="true" [hourFormat]="'12'"
                                        [inputStyleClass]="clicked && createDriveInfo.controls['lastDate'].errors ? 'invalid' : ''">
                                    </p-calendar>
                                    <label for="inputtext">Last Date and Time to apply<span class="imp">*</span></label>
                                    <app-handle-error [clicked]="clicked" [control]="createDriveInfo.get('lastDate')"
                                        name="Last Date and Time to apply"></app-handle-error>
                                </span>
                                <div class="rounds-date-container" *ngIf="!drive_id">
                                    <span class="eventForm-elements-round-title" *ngIf="showRounds">Round 1</span>
                                    <div class="each-rounds-container" *ngIf="showRounds">
                                        <div class="round-date-container">
                                            <span class="p-float-label">
                                                <p-dropdown class="dropdown-rounds" inputId="dropdown-dropdown"
                                                    optionLabel="label" optionValue="value" [options]="roundTypes"
                                                    [autoDisplayFirst]="true" formControlName="roundType" />
                                                <label for="inputtext">Round Type<span class="imp">*</span></label>
                                            </span>

                                            <span class="p-float-label eventForm-elements">
                                                <p-dropdown class="dropdown-rounds" inputId="dropdown-dropdown"
                                                    optionLabel="label" optionValue="value" [options]="dateStatus"
                                                    [autoDisplayFirst]="true" formControlName="roundDateStatus" />
                                                <label for="inputtext">Round Date Status<span
                                                        class="imp">*</span></label>
                                            </span>

                                            <span class="p-float-label eventForm-elements round-date-picker">
                                                <p-calendar [readonlyInput]="true" dateFormat="dd-mm-yy" dataType="date"
                                                    id="inputtext" [minDate]="currentDate" class="calendar-rounds"
                                                    [showTime]="false" [hourFormat]="'12'" [showIcon]="true"
                                                    formControlName="startDate" (onSelect)="resetEndDate()"
                                                    [inputStyleClass]="clicked && createDriveInfo.controls['startDate'].errors ? 'invalid' : ''">
                                                </p-calendar>
                                                <label for="inputtext">Start Date<span class="imp">*</span></label>
                                            </span>

                                            <span class="p-float-label eventForm-elements round-date-picker">
                                                <p-calendar [readonlyInput]="true" dateFormat="dd-mm-yy" dataType="date"
                                                    id="inputtext"
                                                    [minDate]="createDriveInfo.get('startDate')?.value || currentDate"
                                                    class="calendar-rounds" [showTime]="false" [hourFormat]="'12'"
                                                    [showIcon]="true" formControlName="endDate"
                                                    [inputStyleClass]="clicked && createDriveInfo.controls['endDate'].errors ? 'invalid' : ''">
                                                </p-calendar>
                                                <label for="inputtext">End Date<span class="imp">*</span></label>
                                            </span>
                                        </div>
                                        <div class="delete-icon-container">
                                            <button class="delete-icon">
                                                <span class="pi pi-trash" (click)="deleteRound()"></span>
                                            </button>
                                        </div>
                                    </div>

                                    <div class="filter-container-box" (click)="showRound()" *ngIf="!showRounds">
                                        <span class="pi pi-plus-circle"></span>
                                        <p class="add-filter">Add Round</p>
                                    </div>
                                </div>
                                <div class="addl-checkbox-type">
                                    <p-checkbox formControlName="pre_placement_talk" [binary]="true"
                                        (onChange)="prePlacementOption($event)"></p-checkbox>
                                    <span>Pre-placement Talk</span>
                                </div>
                                <span class="p-float-label  eventForm-elements" *ngIf="pre_placement_talk">
                                    <p-calendar [(ngModel)]="prePlacementTalkDate" class="configure-date-picker"
                                        [readonlyInput]="true" formControlName="talk_date" dateFormat="dd-mm-yy"
                                        dataType="date" id="inputtext" [minDate]="currentDate" [showTime]="false"
                                        [hourFormat]="'12'" [showIcon]="true"
                                        [inputStyleClass]="clicked && createDriveInfo.controls['placementDate'].errors ? 'invalid' : ''"
                                        inputId="icondisplay" />
                                    <label for="inputtext">Talk Date<span class="imp">*</span></label>
                                    <app-handle-error [clicked]="clicked" [control]="createDriveInfo.get('talk_date')"
                                        name="Talk Date"></app-handle-error>
                                </span>
                                <div class="flex flex-column create-fields" *ngIf="pre_placement_talk">
                                    <label for="inputtext">Talk Venue</label>
                                    <input formControlName="talk_venue" pInputText type="text">
                                </div>
                                <div class="addl-checkbox-type">
                                    <p-checkbox formControlName="resume_selection" [binary]="true"></p-checkbox>
                                    <span>Request new resume upload</span>
                                </div>
                                <div class="addl-checkbox-type">
                                    <p-checkbox formControlName="opt_out_reason" [binary]="true"
                                        (onChange)="optOutReason($event)"></p-checkbox>
                                    <span>{{'Ask Opt-Out reason' | driveLabelPipe}}</span>
                                </div>
                                <div class="opt-out-checkbox-type flex flex-column" *ngIf="opt_out_reason">
                                    <div class="addl-checkbox-type" *ngFor="let reason of reasons">
                                        <p-checkbox [binary]="true" [formControlName]="reason.controlName"
                                            checked="true" />
                                        <span>{{reason.label | driveLabelPipe}}</span>
                                    </div>
                                </div>
                            </form>
                        </p-accordionTab>

                        <p-accordionTab header="Optional Form">
                            <div class="optional-form-create">
                                <div class="optional-form">
                                    <form [formGroup]="createDriveInfo">
                                        <div class="optional-text-header">
                                            <p-checkbox [binary]="true" formControlName="optional_form_email"
                                                [(ngModel)]="emailChecked"></p-checkbox>
                                            <span>Notify students about Optional Form Update</span>
                                        </div>
                                        <div formArrayName="optionalInformation">
                                            <div
                                                *ngFor="let optionalInfoGroup of optionalInformation.controls; let i = index">
                                                <div [formGroupName]="i">
                                                    <div class="optional-header" #optionalLength>
                                                        <span>Field {{i+1}}</span>
                                                        <div class="mandatory-hide-flex">
                                                            <span class="optional-list-name">
                                                                <p-checkbox [binary]="true"
                                                                    formControlName="edit"></p-checkbox>
                                                                <span>Allow Edit</span>
                                                            </span>
                                                            <span class="optional-list-name">
                                                                <p-checkbox [binary]="true"
                                                                    formControlName="mandatory"></p-checkbox>
                                                                <span>Mandatory</span>
                                                            </span>
                                                            <span class="optional-list-name">
                                                                <p-checkbox [binary]="true"
                                                                    formControlName="hide"></p-checkbox>
                                                                <span>Hide</span>
                                                            </span>
                                                            <img [src]="trashIcon"
                                                                *ngIf="optionalInformation.length >= 1" alt='trash-icon'
                                                                width="19px" (click)="removeField(i)">
                                                        </div>
                                                    </div>
                                                    <div class="optional-body">
                                                        <span class="p-float-label templateForm-elements">
                                                            <p-dropdown class="dropdown" formControlName="fieldType"
                                                                inputId="dropdown" [options]="field_type"
                                                                optionLabel="type" optionValue="code"
                                                                placeholder="Select Field Type"
                                                                (onChange)="onOptionalFieldTypeChange($event, i,'fieldType')"
                                                                [name]="'dropdown' + i" [id]="'dropdown' + i"
                                                                [required]="true">
                                                            </p-dropdown>
                                                            <label for="dropdown">Field Type<span
                                                                    class="imp">*</span></label>
                                                            <app-handle-error [clicked]="clicked"
                                                                [control]="optionalInformation.controls[i].get('fieldType')"
                                                                name="Field Type">
                                                            </app-handle-error>
                                                        </span>
                                                        <div
                                                            *ngIf="optionalInformation.controls[i].get('fieldType')?.value === 'radiobutton'">
                                                            <span class="p-float-label templateForm-elements">
                                                                <input type="text" class="inputtext"
                                                                    placeholder="Enter Question"
                                                                    [id]="'inputtext11' + i" pInputText
                                                                    formControlName="question">
                                                                <label [for]="'inputtext11' + i">Question<span
                                                                        [style]="{color: '#E24A47'}">*</span></label>
                                                                <app-handle-error [clicked]="clicked"
                                                                    [control]="optionalInformation.controls[i].get('question')"
                                                                    name="Question">
                                                                </app-handle-error>
                                                            </span>
                                                            <div class="p-float-label options-enter">
                                                                <p-chips class="chips-form" formControlName="options"
                                                                    [allowDuplicate]="false"
                                                                    placeholder="Type text and press 'Enter' to add"></p-chips>
                                                                <label [for]="'inputtext12' + i">Enter Options <span
                                                                        class="imp">*</span></label>
                                                                <app-handle-error [clicked]="clicked"
                                                                    [control]="optionalInformation.controls[i].get('options')"
                                                                    name="Options">
                                                                </app-handle-error>
                                                            </div>
                                                        </div>

                                                        <div
                                                            *ngIf="optionalInformation.controls[i].get('fieldType')?.value === 'longanswer'">
                                                            <span class="p-float-label templateForm-elements">
                                                                <input type="text" class="inputtext"
                                                                    placeholder="Enter Question"
                                                                    [id]="'inputtext21' + i" pInputText
                                                                    formControlName="question">
                                                                <label [for]="'inputtext21' + i">Question<span
                                                                        [style]="{color: '#E24A47'}">*</span></label>
                                                                <app-handle-error [clicked]="clicked"
                                                                    [control]="optionalInformation.controls[i].get('question')"
                                                                    name="Question">
                                                                </app-handle-error>
                                                            </span>
                                                        </div>

                                                        <div *ngIf="optionalInformation.controls[i].get('fieldType')?.value === 'shortanswer'"
                                                            #shortAnswer>
                                                            <span class="p-float-label templateForm-elements">
                                                                <input type="text" class="inputtext"
                                                                    placeholder="Enter Question"
                                                                    [id]="'inputtext31' + i" pInputText
                                                                    formControlName="question">
                                                                <label [for]="'inputtext31' + i">Question<span
                                                                        [style]="{color: '#E24A47'}">*</span></label>
                                                                <app-handle-error [clicked]="clicked"
                                                                    [control]="optionalInformation.controls[i].get('question')"
                                                                    name="Question">
                                                                </app-handle-error>
                                                            </span>
                                                            <div class="p-float-label create-fields flex gap-10">
                                                                <ng-container *ngFor="let option of shortAnswerOptions">
                                                                    <div class="btn-label">
                                                                        <div class="radio-button"
                                                                            [class.selected]="optionalInformation.controls[i].get('type')?.value === option.value"
                                                                            (click)="onShortAnswerOptionChange(option.value,i,'type')">
                                                                            <div class="inner-circle"></div>
                                                                        </div>
                                                                        <span>{{ option.label }}</span>
                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                        </div>

                                                        <div *ngIf="optionalInformation.controls[i].get('fieldType')?.value === 'dropdown'"
                                                            #dropdown>
                                                            <span class="p-float-label templateForm-elements">
                                                                <input type="text" class="inputtext"
                                                                    placeholder="Enter Question"
                                                                    [id]="'inputtext41' + i" pInputText
                                                                    formControlName="question">
                                                                <label [for]="'inputtext41' + i">Question<span
                                                                        [style]="{color: '#E24A47'}">*</span></label>
                                                                <app-handle-error [clicked]="clicked"
                                                                    [control]="optionalInformation.controls[i].get('question')"
                                                                    name="Question">
                                                                </app-handle-error>
                                                            </span>
                                                            <div class="p-float-label options-enter">
                                                                <p-chips class="chips-form" formControlName="options"
                                                                    [allowDuplicate]="false"
                                                                    placeholder="Type text and press 'Enter' to add"></p-chips>
                                                                <label [for]="'inputtext12' + i">Enter Options <span
                                                                        class="imp">*</span></label>
                                                                <app-handle-error [clicked]="clicked"
                                                                    [control]="optionalInformation.controls[i].get('options')"
                                                                    name="Options">
                                                                </app-handle-error>
                                                            </div>
                                                        </div>

                                                        <div *ngIf="optionalInformation.controls[i].get('fieldType')?.value === 'checkbox'"
                                                            #checkBoxes>
                                                            <span class="p-float-label templateForm-elements">
                                                                <input type="text" class="inputtext"
                                                                    placeholder="Enter Question"
                                                                    [id]="'inputtext51' + i" pInputText
                                                                    formControlName="question">
                                                                <label [for]="'inputtext51' + i">Question<span
                                                                        [style]="{color: '#E24A47'}">*</span></label>
                                                                <app-handle-error [clicked]="clicked"
                                                                    [control]="optionalInformation.controls[i].get('question')"
                                                                    name="Question">
                                                                </app-handle-error>
                                                            </span>
                                                            <div class="p-float-label options-enter">
                                                                <p-chips class="chips-form" formControlName="options"
                                                                    [allowDuplicate]="false"
                                                                    placeholder="Type text and press 'Enter' to add"></p-chips>
                                                                <label for="inputtext">Enter Options<span
                                                                        class="imp">*</span></label>
                                                                <app-handle-error [clicked]="clicked"
                                                                    [control]="optionalInformation.controls[i].get('options')"
                                                                    name="Options">
                                                                </app-handle-error>
                                                            </div>
                                                        </div>

                                                        <div *ngIf="optionalInformation.controls[i].get('fieldType')?.value === 'fileupload'"
                                                            #fileUpload>
                                                            <span class="p-float-label templateForm-elements">
                                                                <input type="text" class="inputtext"
                                                                    placeholder="Enter Question"
                                                                    [id]="'inputtext61' + i" pInputText
                                                                    formControlName="question">
                                                                <label [for]="'inputtext61' + i">Question<span
                                                                        [style]="{color: '#E24A47'}">*</span></label>
                                                                <app-handle-error [clicked]="clicked"
                                                                    [control]="optionalInformation.controls[i].get('question')"
                                                                    name="Question">
                                                                </app-handle-error>
                                                            </span>
                                                            <div class="file_upload mr-bottom">
                                                                <div class="optional-text-header">
                                                                    <p-checkbox [binary]="true"
                                                                        (onChange)="showSpecificFileTypes($event, i)"
                                                                        formControlName="allowSpecificFileTypes"></p-checkbox>
                                                                    <label for="inputtexttypes">Allow only specific file
                                                                        types</label>
                                                                </div>
                                                                <span class="p-float-label"
                                                                    *ngIf="optionalInformation.controls[i].get('allowSpecificFileTypes')?.value">
                                                                    <p-multiSelect class="dropdown" inputId="dropdown"
                                                                        optionLabel="label" optionValue="value"
                                                                        [options]="fileUploadOptions"
                                                                        formControlName="fileTypes" [required]="true"
                                                                        placeholder="Select File Types">
                                                                    </p-multiSelect>
                                                                    <label for="inputtext">Select File Types<span
                                                                            class="imp">*</span>
                                                                    </label>
                                                                </span>
                                                            </div>

                                                            <div class="p-float-label create-fields">
                                                                <p-dropdown [options]="file_size"
                                                                    placeholder="Select File Size"
                                                                    formControlName="selectedFileSize"
                                                                    (onChange)="onOptionalFieldFileSizeChange($event,i,'selectedFileSize')"
                                                                    [name]="'filesize' + i" [id]="'filesize' + i"
                                                                    [appendTo]="'body'"></p-dropdown>
                                                                <label for="inputtext">Accept upto 5mb</label>
                                                            </div>
                                                        </div>

                                                        <div *ngIf="optionalInformation.controls[i].get('fieldType')?.value === 'termscondition'"
                                                            #termsCondition>
                                                            <span class="p-float-label templateForm-elements">
                                                                <input type="text" class="inputtext"
                                                                    placeholder="Enter Question"
                                                                    [id]="'inputtext71' + i" pInputText
                                                                    formControlName="question">
                                                                <label [for]="'inputtext71' + i">Question<span
                                                                        [style]="{color: '#E24A47'}">*</span></label>
                                                                <app-handle-error [clicked]="clicked"
                                                                    [control]="optionalInformation.controls[i].get('question')"
                                                                    name="Question">
                                                                </app-handle-error>
                                                            </span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="new-field-class" (click)="addNewFieldForOptionalForm();">
                                            <img [src]="plusIcon" alt="add-icon">
                                            <span> Add New Field on optional form</span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </p-accordionTab>
                    </ng-container>
                </p-accordion>
            </div>
        </div>
        <div *ngIf="currentTab == 'eligibility'" style="height:100%">
            <app-eligibility-criteria 
                [drive_id]="drive_id" 
                (publishDrive)="openPublishDriveDialog($event)"
                [criteriaFilters]="criteriaFilters" 
                [exemptPlacementPolicy]="exemptPlacementPolicy" 
                (refreshInfo)="showCriteriaRefreshAlert($event)"
                [driveDetails]="driveUpdatingData"
            >
            </app-eligibility-criteria>
        </div>
        <div *ngIf="currentTab == 'interviewProcess'">
            <app-rounds [driveData]="driveUpdatingData" [driveStatus]="originalDriveInfo.driveStatus">
            </app-rounds>
        </div>
        <div *ngIf="currentTab == 'offerConfirmation'">
            <app-rounds [onlyPlaced]="true" [driveData]="driveUpdatingData"
                [driveStatus]="originalDriveInfo.driveStatus">
            </app-rounds>
        </div>
        <div *ngIf="currentTab == 'emailTemplate'" class="drive-email-template">
            <app-drive-email-template [email_templates]="email_templates"
                (updateEmailTemplete)="updateEmailTemplete($event)"></app-drive-email-template>
        </div>
        <div *ngIf="currentTab === 'rounds'">
            <app-drive-round-creation [driveData]="driveUpdatingData"
                [driveStatus]="originalDriveInfo.driveStatus"></app-drive-round-creation>
        </div>
    </div>
</div>
<p-toast></p-toast>
<p-confirmDialog [style]="{width: '35vw'}"></p-confirmDialog>

<!-- Drive Clash Details -->
<p-dialog header="Drive Clash Details" [(visible)]="showDateClashDialog" [modal]="true" [draggable]="false"
    [resizable]="false" [blockScroll]="true" [style]="{height: '500px'}" (onHide)="onHideDriveClashDialog()">
    <ng-template pTemplate="header">
        <div class="header-cont">
            <span>Drive Clash Details</span>
        </div>
    </ng-template>

    <ng-template pTemplate="body">
        <div class="preview-drive-clash">
            <p-table styleClass="p-datatable-gridlines" [columns]="tablecolumns" [value]="dataToDisplay">
                <ng-template pTemplate="caption">
                    <div class="caption-header">
                        <div class="totallist-text">Total List : {{dateClashDrives.length}}</div>
                        <p-divider layout="vertical"></p-divider>
                        <button pButton class="disabled p-button-secondary p-button-text simple-btn">
                            {{ currentPage }} to {{ totalPage }}
                        </button>
                        <div class="page-front-back">
                            <span><i class="pi pi-angle-left arrow-button" (click)="previousPagination()"></i></span>
                            <span><i class="pi pi-angle-right arrow-button" (click)="nextPagination()"></i></span>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" scope="col">
                            {{ col.header }}
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr>
                        <ng-container *ngFor="let col of columns">
                            <td *ngIf="col.field == 'drive_status'else otherCols" class="truncate-cell"
                                [style.width]="col.width" pFrozenColumn>
                                <span class="drive-status-label" [ngClass]="{
                                    'saved-label': rowData.drive_status === patDriveStatus.saved, 
                                    'published-label': rowData.drive_status === patDriveStatus.upcoming,
                                    'onHold-label': rowData.drive_status === patDriveStatus.hold,
                                    'ended-label': rowData.drive_status === patDriveStatus.completed,
                                    'active-label': rowData.drive_status === patDriveStatus.ongoing,
                                }">
                                    {{rowData.drive_status}}
                                </span>
                            </td>
                            <ng-template #otherCols>
                                <td class="truncate-cell" [style.width]="col.width" pFrozenColumn>
                                    <span [pTooltip]="rowData[col.field]" [tooltipPosition]="'top'">
                                        {{ rowData[col.field] ? rowData[col.field] : "-" }}
                                    </span>
                                </td>
                            </ng-template>
                        </ng-container>
                    </tr>
                </ng-template>
            </p-table>
        </div>

    </ng-template>
</p-dialog>


<!-- publish Drive  -->

<p-dialog header="Publish Drive" [(visible)]="displayPublishDialog" [modal]="true" [draggable]="false"
    [resizable]="false" [style]="{width: '1000px',  height: '500px'}" (onHide)="onHidePublishDialog()"
    class="publish-drive-dialog">
    <ng-template pTemplate="header">
        <div class="header-cont remove-padding">
            <div class="header-container-divider">
                <span> {{ subTitleMessages[currentTabSelected].title }} </span>
                <div class="preview-divider"></div>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="body">
        <div class="publish-drive-title">
            {{ subTitleMessages[currentTabSelected].subTitle }}
        </div>
        <ng-container *ngIf="isPlacementPolicyEnabled">
            <div class="publish-drive-title-msg">
                <b>Note:</b> Placement policy rules are {{ exemptPlacementPolicy ? 'exempted' : 'included' }} for this drive
            </div>
        </ng-container>
       
        <div class="publish-checkbox" *ngIf="currentTabSelected != 'refresh'">
            <div class="publish-drive-title-msg" *ngIf="isDriveInRepublishQueue">
                Drive is already in Republish Queue. Do you wish to continue?
            </div>
            <div *ngIf="isEmailEnabled && isAdminEmailEnabled">
                <p-checkbox [binary]="true" [(ngModel)]="sendEmailToAdmins" ></p-checkbox>
                <label>
                  Do you wish to send emails to <span class="bolder">Admins</span>
                </label>
            </div>
            <div *ngIf="!isEmailEnabled">
                Email Notification is Disabled. Your recipients will not receive any email. Do you wish to continue?
            </div>
            <div *ngIf="!isAllNotificationDisabled()">
                <p-checkbox [binary]="true" [(ngModel)]="sendEmailNotification"></p-checkbox>
                Do you wish to notify <span class="bolder">Not eligible students.</span>
            </div>
            <div class="error-title-box" *ngIf="isAnyNotificationDisabled">
                <span>
                    Some notifications are disabled in your settings, students may not receive updates. Enable them by updating your settings.
                </span>
            </div>
        </div>

        <div *ngFor="let previewData of criteriaToDisplay; let i = index">

            <div class="preview-title-criteria">
                <span>Criteria {{ i + 1 }}</span>
            </div>

            <div class="no-changes-text"
                *ngIf="!previewData['basicCriteria']?.length && !previewData['placementFilters']?.length && !previewData['internshipFilters']?.length && !previewData['customCriteria']?.length">
                <span>No Changes made</span>
            </div>

            <div class="preview-drive-clash">

                <div class="preview-title" *ngIf="previewData['basicCriteria']?.length">
                    <span>Basic Filters</span>
                </div>

                <div *ngFor="let item of previewData['basicCriteria']">
                    <div class="preview-text-container">
                        <div class="preview-key">
                            <span>{{ item.label }}</span>
                        </div>
                        <div class="preview-value">
                            <span>{{ item.value || '-'}}</span>
                        </div>
                    </div>
                </div>

                <div class="preview-title" *ngIf="previewData['placementFilters']?.length">
                    <span>Placement Filters</span>
                </div>

                <div *ngFor="let item of previewData['placementFilters']">
                    <div class="preview-text-container">
                        <div class="preview-key">
                            <span>{{ item.label }}</span>
                        </div>
                        <div class="preview-conditon">
                            <span> {{ item.condition }} </span>
                        </div>
                        <div class="preview-value-filter">
                            <span>{{ item.value || '-'}}</span>
                        </div>
                    </div>
                </div>

                <div class="preview-title" *ngIf="previewData['internshipFilters']?.length">
                    <span>Internship Filters</span>
                </div>

                <div *ngFor="let item of previewData['internshipFilters']">
                    <div class="preview-text-container">
                        <div class="preview-key">
                            <span>{{ item.label }}</span>
                        </div>
                        <div class="preview-conditon">
                            <span> {{ item.condition }} </span>
                        </div>
                        <div class="preview-value-filter">
                            <span>{{ item.value || '-'}}</span>
                        </div>
                    </div>
                </div>

                <div class="preview-title" *ngIf="previewData['customCriteria']?.length">
                    <span>Custom field Filters</span>
                </div>

                <div *ngFor="let item of previewData['customCriteria']">
                    <div class="preview-text-container">
                        <div class="preview-key">
                            <span>{{ item.label }}</span>
                        </div>
                        <div class="preview-conditon">
                            <span> {{ item.condition }} </span>
                        </div>
                        <div class="preview-value-filter">
                            <span>{{ item.value || '-'}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <div class="flex justify-end publish-btns" *ngIf="currentTabSelected != 'refresh'">
            <app-button [btnProperties]="btnProperties['publishDrive']" (buttonClick)="publishDrive()"></app-button>
        </div>
    </ng-template>
</p-dialog>