import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { DriveService, GlobalService, HttpService, SettingsService } from '@services'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastModule } from 'primeng/toast';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SidebarModule } from 'primeng/sidebar';
import { AuthInterceptor } from './interceptor/auth.interceptor';
import { NgDynamicBreadcrumbModule } from 'ng-dynamic-breadcrumb';
import { SharedModule } from 'primeng/api';
import { DriveDetailsModule } from './module/components/drive-details/drive-details.module';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { MessageInsightsModule } from './module/components/message-insights/message-insights.module';
import { CookieService } from 'ngx-cookie-service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    HttpClientModule,
    ToastModule,
    FormsModule,
    ReactiveFormsModule,
    NgDynamicBreadcrumbModule,
    ServiceWorkerModule.register('ngsw-worker.js', {  /* Register the ServiceWorker as soon as the application is stable or after 30 seconds (whichever comes first).*/
      enabled: true,
      registrationStrategy: 'registerWhenStable:30000'
    }),
    SidebarModule,
    // provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    // provideMessaging(() => getMessaging()),
    SharedModule,
    NgxDropzoneModule,
    DriveDetailsModule,
    MessageInsightsModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [DriveService, GlobalService, HttpService, SettingsService, CookieService, 
    // provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    // provideMessaging(() => getMessaging()),
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
