import { GlobalService, HttpService } from '@services';
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export class SettingsService {

    constructor(
        private httpService: HttpService,
        private globalService: GlobalService
    ) { }


    async fetchAccountMetadata() {
        try {
            const { data, success } = await this.fetchAccountMetaData()
            if (!success || !data) return;
            this.globalService.setAccountDetails(data);
        } 
        catch (error) {
            console.error('Error in fetchMetaData', error)
        }
    }

    async fetchAccountMetaData() {
        try {
            return await this.httpService.get(`/settings/account-metadata`);
        } catch (error) {
            throw new Error('Error in fetchAccountMetaData')
        }
    }

    async updatePlacementPolicy(payload: any) {
        try {
            const res = await this.httpService.put('/settings/placement-policy', payload)
            return res
        } catch (error) {
            throw new Error('Error in updatePlacementPolicy')
        }
    }

    async updateAccountMetaData(payload: any) {
        try {
            const res = await this.httpService.put('/settings/account-metadata', payload)
            return res
        } catch (error) {
            throw new Error('Error in updateAccountMetaData')
        }
    }

    async saveFields(payload: any) {
        try {
            const res = await this.httpService.put('/settings/account-fields', payload)
            return res
        } catch (error) {
            throw new Error('Error in saveFields')
        }
    }

    async validateSenderAddresses(payload: any) {
        return await this.httpService.post('/settings/sender-addresses/validate', payload)
    }

    async updateCollegeProfile(payload: any) {
        return await this.httpService.put(`/settings/college-profiles`, payload)
    }

    async updateCampusDetails(payload: any) {
        return await this.httpService.put(`/settings/college-profiles/campus`, payload)
    }

    async fetchChoices(attribute_id: string) {
        const fetchChoices =
            await this.httpService.get(
                `/studentFields/fetchChoices/${attribute_id}`,
            );
        return fetchChoices;
    }

    async countStudentsWithAttribute(payload: any) {
        const fetchCount =
            await this.httpService.post(
                `/studentFields/countStudentsWithAttribute`,
                payload
            );
        return fetchCount;
    }


    async saveFieldMappings(body: any) {
        const addCategory =
            await this.httpService.post(
                '/studentFields/saveFieldMappings',
                body
            );
        return addCategory;
    }

    async updateAccountSettings(body: any) {
        const addCategory =
            await this.httpService.put(
                '/settings/change/password',
                body
            );
        return addCategory;
    }

    async passwordCheck(body: any) {
        const addCategory =
            await this.httpService.post(
                '/auth/password/check',
                body
            );
        return addCategory;
    }

    async updateProgramGroups(payload: any) {
        return await this.httpService.put(`/settings/college-profiles/program-groups`, payload)
    }
}