import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { FileUploadService, GlobalService } from '@services';
import { btnProperties } from '@models';
import { Message, MessageCategoryOptions, MessageTypeOptions, SBtnProperties, Severity, Students, Summary } from '@enum';
import * as XLSX from 'xlsx';
import { MessageService } from 'primeng/api';
import { v4 as uuidv4 } from 'uuid';



declare let tinymce: any;
@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.css']
})

export class SendEmailComponent implements OnInit {

  @Input() sendemailslider: boolean = false;
  @Input() filterData: any = [];
  @Input() selectedFilters: any = {};
  @Output() closesideBar: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() loading: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() studentDataList: any = [];
  @Input() selectAllStudentCount: number = 0;
  @Input() selectAllStudents: boolean = false;
  @Input() roundBasedEmail: boolean = false;
  @Input() driveMailInfo: any = {};
  @Input() search: string = '';

  templatesList: any = [];
  userData: any = localStorage.getItem('user_details')
  accountData: any = localStorage.getItem('account_details')
  account_metadata: any = localStorage.getItem('account_metadata');
  group_account_id: string = '';
  clicked: boolean = false;
  selectedStudentsCount: number = 2;
  studentData: any = [];
  btnProperties: { [key: string]: btnProperties } = SBtnProperties
  bucketData: any = {};
  excel: any;
  preview: boolean = false;
  mediaFile: File[] = [];
  isFileRemoved: boolean = true;
  previewColumn: any[] = [];
  previewDataList: any[] = [];
  pageSize: number = 50;
  currentPreviewPage: number = 1;
  totalPreviewPage: number = 0;
  displayDataList: any[] = [];
  defaultEditorPlaceholder: string = 'Email Description';
  messageTypeOptions = MessageTypeOptions;
  messageCategoryOptions = MessageCategoryOptions;
  selectedMessageType: string | null = null;
  uploadingFile: boolean = false;
  sendEmailForm: FormGroup;
  toAddress: string[] = [];
  subjectTitle: string = '';
  isFileUploading: boolean = false;
  emails: any[] = [];
  emailPattern: any = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24}$/;
  invalidEmails: any[] = [];
  invalidEmailsMap: Map<string, boolean> = new Map();
  duplicates: string[] = [];
  isInvalidEmails: boolean = false;
  studentsEnum: any = Students;
  campaignType: any = '';
  invalidEmailsList: any[] = [];
  validUserIds: any[] = [];
  validEmailObj: any ;
  showSendEmail: boolean = false;
  showForm: boolean = false;
  adminEmailOptions: any[] = [];
  selectedCcEmails: any[] = [];
  selectAllChecked: boolean = false; 
  
  constructor(private fb: FormBuilder, public globalService: GlobalService,
    private fileUploadService: FileUploadService,
    private messageService: MessageService
  ) {
    this.userData = JSON.parse(this.userData)
    this.accountData = JSON.parse(this.accountData)
    this.sendEmailForm = this.fb.group({
      template: [''],
      content: [''],
      category: [''],
      messageType: [''],
      subjectTitle: [this.subjectTitle],
      attachment: [[]],
      emails: [[]],
      from: this.accountData.from_address,
      ccEmails: [[]],
    });
    this.getAdminEmails();
  }

  async ngOnInit() {
    this.group_account_id = this.accountData.group_account_id;
    await this.getAdminEmails();  
  }
  
  async getAdminEmails() {
    try {
      const response = await this.globalService.getAllAdmins();
      this.adminEmailOptions = response?.data?.map((admin: any) => ({
        label: admin.primary_email,
        value: admin.primary_email
      })) || [];
    } catch (error) {
      console.error('Error fetching admin emails:', error);
    }
  }
  
  
  
  onCcEmailChange(selectedValues: string[]) {
    const allEmails = this.adminEmailOptions.map(option => option.value);
    this.selectedCcEmails = selectedValues.filter(value => value !== 'all');
    this.selectAllChecked = this.selectedCcEmails.length === allEmails.length;
    this.sendEmailForm.get('ccEmails')?.setValue([...this.selectedCcEmails]);
  }
  
  toggleSelectAll(event: Event) {
    const checked = (event.target as HTMLInputElement).checked;
    const allEmails = this.adminEmailOptions.map(option => option.value);
    this.selectAllChecked = checked;
    this.selectedCcEmails = checked ? [...allEmails] : [];
    this.sendEmailForm.get('ccEmails')?.setValue([...this.selectedCcEmails]);
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['sendemailslider'] && changes['sendemailslider'].currentValue === true) {
      this.selectAllChecked = false;
      this.loading.emit(true);
      this.getAllTemplates();
      this.loading.emit(false);
    }
  }

  async fileUpload(files: any) {
    try {
      const totalFiles = (this.sendEmailForm.get('attachment')?.value || []).concat(files.addedFiles);
      const maxFilesCount = 10;
      const maxTotalSize = 7 * 1024 * 1024; // 7MB in bytes
      const totalFileSize = totalFiles.reduce((acc: any, file: any) => acc + file.size, 0);
      console.log(totalFiles);

      if (totalFileSize > maxTotalSize) {
        this.messageService.add({
          severity: Severity.ERROR,
          summary: Message.FILESIZE,
          detail: `Total file size must not exceed 7MB.`
        });
        return;
      }

      if (totalFileSize.length > maxFilesCount) {
        return this.messageService.add({
          severity: Severity.ERROR,
          summary: Message.MAXFILES,
          detail: `You can only upload maximum ${maxFilesCount} files.`
        });
      }

      this.isFileUploading = true;
      const filesToUpload = files.addedFiles;

      const uploadPromises = filesToUpload.map(async (file: any) => {

        let fileName = file.name.split('.');
        let fileExtension = fileName.pop();
        fileName = `${fileName.join('').replace(/\s/g, '').replace(/[^\w\s]/gi, '')}.${fileExtension}`;

        this.group_account_id = this.accountData.group_account_id;
        const filePath = `${this.group_account_id}/send-email/${uuidv4()}/${fileName}`;
        this.bucketData = {
          bucketName: this.globalService.bucketName,
          fileName: filePath,
          type: file.type,
        };
        const url = await this.globalService.getSignedUploadUrl(this.bucketData);
        if (url?.data?.response) {
          await this.fileUploadService.uploadUsingSignedUrl(url.data.response, file);
          const attachments = this.sendEmailForm.get('attachment')?.value || [];
          attachments.push({
            filePath,
            size: file.size
          });
          this.sendEmailForm.get('attachment')?.setValue(attachments);
          this.messageService.add({
            severity: Severity.SUCCESS,
            summary: Summary.UPLOADSUCCESS,
            detail: `File ${file.name} uploaded successfully.`
          });
        } else {
          throw new Error("Failed to obtain signed URL for " + file.name);
        }
      });
      await Promise.all(uploadPromises);
      this.isFileUploading = false;
    } catch (error) {
      console.error('Error uploading files', error);
      this.messageService.add({
        severity: Severity.ERROR,
        summary: Summary.UPLOADERR,
        detail: 'There was an error uploading files.'
      });
    } finally {
      this.uploadingFile = false;
    }
  }

  onMessageTypeChange(event: any): void {
    let enabledOptions = this.getEnabledNotifications();
    if(!enabledOptions.includes(event.value)) {
      this.showForm = false;
      this.messageService.add({
        severity: Severity.WARN,
        summary: Summary.WARNING,
        detail: event.value === 'email' ? Message.MAILDISABLED : Message.PUSHDISABLED
      });
      return;
    }
    this.showForm = true;
    this.sendEmailForm.reset();
    this.selectedMessageType = event.value;
    this.sendEmailForm.get('messageType')?.setValue(this.selectedMessageType);
  
    const subjectTitleControl = this.sendEmailForm.get('subjectTitle');
    if (this.selectedMessageType !== 'whatsapp') {
    const previousCcEmails = this.sendEmailForm.get('ccEmails')?.value || [];
    this.sendEmailForm.reset({
      messageType: event.value,
      from: this.accountData.from_address,
      ccEmails: previousCcEmails
    });

    this.selectedMessageType = event.value;
    this.sendEmailForm.get('messageType')?.setValue(this.selectedMessageType);

    const subjectTitleControl = this.sendEmailForm.get('subjectTitle');
    if (this.selectedMessageType !== 'whatsapp') {
      subjectTitleControl?.setValidators([Validators.required]);
    } else {
      subjectTitleControl?.clearValidators();
    }
    subjectTitleControl?.updateValueAndValidity();
    
    this.sendEmailForm.get('content')?.setValue('');
    setTimeout(() => {
      this.loadTinyMce();
    }, 500);
  
    this.showSendEmail = this.studentDataList.length || this.selectAllStudentCount;
    this.validEmailObj = new Map(this.studentDataList.map((user: any) => [user.primary_email, user]))
  }
}
  
  async validateEmail() {
    let email_payload = {
      emails: this.sendEmailForm.get('emails')?.value,
      group_account_id: this.group_account_id,
      type: this.selectedMessageType
    };
    if (email_payload.emails.length === 0 || this.invalidEmailsMap.size > 0) {
      this.showSendEmail = false;
      this.messageService.add({
        severity: Severity.ERROR,
        summary: Summary.ERROR,
        detail: Message.VALIDEMAIL
      });
      return;
    }

    this.loading.emit(true);
    let response = await this.globalService.validateEmail(email_payload);
    this.invalidEmailsList = response.data.notValidateEmail;
    this.validUserIds = response.data.validUserIds;
    this.validEmailObj = new Map(response.data.validateEmail.map((user: any) => [user.primary_email, user]))

    if (this.invalidEmailsList.length === 0) {
      this.showSendEmail = true;
      this.messageService.add({
        severity: Severity.SUCCESS,
        summary: Summary.SUCCESS,
        detail: Message.EMAILSVALID
      });
    }
    else {
      this.messageService.add({
        severity: Severity.WARN,
        summary: Summary.INVALID,
        detail: this.selectedMessageType === 'whatsapp' ? Message.INVALIDWPEMAIL : Message.EXISTINGEMAIL
      });
    }
    this.loading.emit(false);
  }

  async handlePaste(event: ClipboardEvent) {
    event.preventDefault();
    const clipboardData: any = event.clipboardData;
    const pastedText = clipboardData.getData('text');
    // Split the pasted text into individual emails using newlines and spaces as separators
    let pastedEmails = pastedText.split(/[\n\s]+/).map((email: any) => email.trim().toLowerCase());
    if (pastedEmails.length > 0) {
      let currentEmails = this.sendEmailForm.get('emails')?.value || [];
      let validEmails = currentEmails.filter((email: any) => this.emailPattern.test(email) && !pastedEmails.includes(email));
      let newEmails = [...validEmails, ...pastedEmails];
      this.sendEmailForm.get('emails')?.setValue(newEmails);
      this.invalidEmails = await this.validateEmails(newEmails);
    }
  }

  async validateEmails(emails: string[]) {
    const invalidEmails: string[] = [];
    const seenEmails: Set<string> = new Set();
    const duplicates: Set<string> = new Set();
    for (const email of emails) {
      const trimmedEmail: string = email.replace(/,\s*$/, '').trim();
      if (!this.emailPattern.test(trimmedEmail)) {
        this.invalidEmailsMap.set(trimmedEmail, true);
        invalidEmails.push(trimmedEmail);
      }
      if (seenEmails.has(trimmedEmail)) {
        duplicates.add(trimmedEmail);
      } else {
        seenEmails.add(trimmedEmail);
      }
    }

    for (const mail of this.invalidEmailsMap.keys()) {
      if (!duplicates.has(mail) && !invalidEmails.includes(mail)) {
        this.invalidEmailsMap.delete(mail);
      }
    }
    this.duplicates = Array.from(duplicates);
    for (const duplicate of duplicates) {
      this.invalidEmailsMap.set(duplicate, true);
    }

    this.isInvalidEmails = invalidEmails.length > 0 || duplicates.size > 0;
    return invalidEmails;
  }

  isInvalidEmail(email: string): boolean {
    const trimmedEmail = email.trim();
    return this.invalidEmailsMap.has(email) || this.invalidEmailsList.includes(trimmedEmail);
  }

  removeFile(idx: number) {
    const attachments = this.sendEmailForm.get('attachment')?.value;
    attachments.splice(idx, 1);
    this.sendEmailForm.get('attachment')?.setValue(attachments);
  }

  async openLink(url: string) {
    window.open(url, '_blank');
  }

  handleRemove(event: any) {
    this.validateEmails(this.sendEmailForm.get('emails')?.value);
  }

  async handleKeydown(event: any) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.emails = this.sendEmailForm.get('emails')?.value.map((mail: any) => mail.toLowerCase())
      this.invalidEmails = await this.validateEmails(this.emails);
    }
  }

  async handleComplete(event: any) {
    this.emails = this.sendEmailForm.get('emails')?.value.map((mail: any) => mail.toLowerCase())
    this.invalidEmails = await this.validateEmails(this.emails);
  }

  onRemoveMedia(event: any) {
    this.mediaFile.splice(this.mediaFile.indexOf(event), 1);
    this.isFileRemoved = true;
    this.uploadingFile = false;
    this.previewDataList = [];
    this.displayDataList = [];
    this.previewColumn = [];
  }

  async getAllTemplates() {
    this.group_account_id = this.accountData.group_account_id;
    const payload = {
      template_type: 'customTemplates',
      category: 'all'
    };

    const response = await this.globalService.fetchAllTemplate(payload);
    this.templatesList = response.data;
  };

  async sendMessage() {
    if (!this.validateForm()) {
        this.showWarningMessage();
        return;
    }

    this.loading.emit(true);
    this.clicked = true;

    try {
        const payload = await this.buildPayload();
        const response = await this.sendMessageBasedOnType(payload);
        this.handleResponse(response);
    } catch (error) {
        console.error("Error sending message:", error);
    } finally {
        this.loading.emit(false);
        this.clicked = false;
    }
}

  private validateForm(): boolean {

    if (this.selectedMessageType === 'email') {
      this.toAddress = this.selectAllStudents
        ? []
        : this.studentDataList.map((student: any) => {
          return {
            email: student.primary_email,
            first_name: student.first_name,
            last_name: student.last_name,
            middle_name: student.middle_name
          }
        });
    }
    else {
      this.toAddress = this.selectAllStudents
        ? []
        : this.studentDataList.map((student: any) => ({ 'user_id': student.user_id }));
    }

    const emailsControl = this.sendEmailForm.get('emails');
    if (!emailsControl?.value?.length) {
      emailsControl?.setValue(this.toAddress);
    }

    if (!this.sendEmailForm.valid && !this.selectAllStudents) {
      return false;
    }

    const content = this.sendEmailForm.get('content')?.value;
    const category = this.sendEmailForm.value.category;

    if (!content ||
      (this.selectedMessageType === 'email' && !this.campaignType) ||
      (this.selectedMessageType === 'pushNotification' && !category)) {
      return false;
    }

    return true;
  }

  async templateImageUpload(data: string): Promise<string> {
    const elem: any = document.createElement('div');
    elem.innerHTML = data;
    const imgArray = elem.getElementsByTagName('img');
    const promiseArray: Promise<any>[] = [];

    for (const match of imgArray) {
      if (
        match.src.indexOf('storage.cloud') === -1 &&
        match.src.indexOf('base64') !== -1
      ) {
        promiseArray.push(
          (async () => {
            const pay = {
              bucketName: this.globalService.publicBucket,
              fileName: this.group_account_id + '/email-attachments/' + uuidv4(),
              type: match.src.split(';base64')[0].split(':')[1],
            };

            try {
              const url = await this.globalService.getSignedUploadUrl(pay);

              if (url?.data) {
                const json = url;
                const response: any = await this.fileUploadService.uploadUsingSignedUrl(json.data.response, this.globalService.dataURItoBlob(match.src, pay.type));
                if (response) {
                  const newUrl = `${this.globalService.prefix}/${this.globalService.publicBucket}/${pay.fileName}`;
                  match.src = newUrl;
                  return newUrl;
                }
              }
              return null; // Ensure a value is returned if url or response is falsy
            } catch (error) {
              console.error(error);
              return null; // Ensure a value is returned in case of an error
            }
          })()
        );
      }
    }

    await Promise.all(promiseArray);
    return elem.innerHTML;
  }

  private async buildPayload(): Promise<any> {
    const basePayload = {
      group_account_id: this.accountData.group_account_id,
      subject: this.sendEmailForm.value.subjectTitle,
      template_content: this.sendEmailForm.get('content')?.value,
      roundBasedEmail: this.roundBasedEmail,
      ...(this.roundBasedEmail && { driveDetails: this.driveMailInfo })
    };

    basePayload.template_content = await this.templateImageUpload(basePayload.template_content);

    if (this.selectAllStudents) {
      Object.assign(basePayload, {
        filters: this.selectedFilters,
        count: this.selectAllStudentCount,
        search: this.search,
      });
    }

    return this.selectedMessageType === 'email'
      ? this.buildEmailPayload(basePayload)
      : this.buildNotificationPayload(basePayload);
  }

  private buildEmailPayload(basePayload: any): any {
    let toAddress: any = this.sendEmailForm.value.emails?.map((each: any) => 
    {
      let user: any = this.validEmailObj?.get(each?.email || each);
      return {
        primary_email: user.primary_email,
        first_name: user?.first_name,
        last_name: user?.last_name,
        middle_name: user?.middle_name
      }
    });
    const payload = {
      ...basePayload,
      attachments: this.sendEmailForm.value.attachment?.map((each: any) => each.filePath),
      campaign_type: this.campaignType,
      senderEmail: this.userData.primary_email,
      ccEmails: Array.isArray(this.sendEmailForm.value.ccEmails)
        ? this.sendEmailForm.value.ccEmails
        : this.sendEmailForm.value.ccEmails
          ? [this.sendEmailForm.value.ccEmails]
          : [],
      toAddress: toAddress?.length ? toAddress : this.toAddress,
    };
    return payload;
  }

  private buildNotificationPayload(basePayload: any): any {
    return {
      ...basePayload,
      category: this.sendEmailForm.value.category,
      userData: typeof this.toAddress[0] === 'object'
        ? this.toAddress
        : this.validUserIds.map(id => ({ 'user_id': id })),
      template_content: this.stripHtmlTags(basePayload.template_content),
    };
  }

  private async sendMessageBasedOnType(payload: any): Promise<any> {
    switch (this.selectedMessageType) {
      case 'email':
        return this.globalService.sendEmail(payload);
      case 'pushNotification':
        return this.globalService.sendNotification(payload);
      case 'whatsapp':
        return this.globalService.sendWhatsappNotification(payload);
    }
  }

  private showWarningMessage() {
    this.messageService.add({
      severity: Severity.WARN,
      summary: Summary.WARNING,
      detail: Message.MANDATORY,
    });
  }

  private handleResponse(response: any) {
    if (response.success) {
      this.closeSidebar();
      this.messageService.add({
        severity: Severity.SUCCESS,
        summary: Summary.SUCCESS,
        detail: Message.MESSAGESUCCESS,
      });
    } else {
      this.messageService.add({
        severity: Severity.ERROR,
        summary: Summary.ERROR,
        detail: Message.MESSAGEERR,
      });
    }
  }

  private stripHtmlTags(htmlContent: string): string {
    const div = document.createElement("div");
    div.innerHTML = htmlContent;
    return div.textContent || div.innerText || "";
  }

getEnabledNotifications() {
  try {
      const account_metadata = JSON.parse(this.account_metadata);
      if (!account_metadata?.general) return [];
      const { general } = account_metadata;
      const notificationMap: any = {
        'email': 'email_notification',
        'pushNotification': 'push_notification'
      };
      return Object.keys(notificationMap).filter(key => general[notificationMap[key]] !== false);
    } catch (error) {
      console.error('Error processing notifications:', error);
      return [];
    }
  }

  isAllNotificationDisabled() {
    const { general } = JSON.parse(this.account_metadata);
    return !(general?.push_notification || general?.email_notification);
  }

  closeSidebar() {
    this.selectAllStudentCount = 0;
    this.closesideBar.emit(false);
    this.sendemailslider = false;
    this.clicked = false;
    this.sendEmailForm.reset();
    this.sendEmailForm.get('content')?.setValue('');
    this.selectedMessageType = '';
    this.showSendEmail = false;
    this.loading.emit(false);
  }

  async onSelectExcel(event: any, type: any) {
    const file = event.addedFiles[0];
    const reader = new FileReader();

    reader.onload = async (e: any) => {
      try {
        const workbook = XLSX.read(e.target.result, { type: 'binary' });
        const sheetName = type === 'invite' ? workbook.SheetNames[1] : workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, {
          defval: '-',
          dateNF: 'DD-MM-YYYY',
          raw: false
        });
        if (jsonData.length === 0) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Uploaded excel is empty!',
          });
          return;
        };

        let firstData: any = jsonData[0];
        if (Object.keys(firstData).length === 1 && type === 'update') {
          this.messageService.add({
            severity: 'warn',
            summary: 'Error',
            detail: 'Cannot update with single column.',
          });
          return;
        };

        this.excel = event.addedFiles;
        this.mediaFile.push(...event.addedFiles);
        this.isFileRemoved = false;
        this.handleUpload(type);

        const isValid = await this.validateAndProcessData(jsonData);
        if (!isValid) {
          this.messageService.add({
            severity: 'warn',
            summary: 'Empty Column',
            detail: 'Empty Column names found.',
          });
          this.preview = isValid;
          this.isFileRemoved = true;
          this.mediaFile = [];
          return;
        }
        this.preview = isValid;
      } catch (error) {
        console.error('Error reading Excel file:', error);
        this.preview = false;
      }
    };
    reader.readAsBinaryString(file);
  }

  handleUpload(type: any) {
    const file = this.excel[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.fileUpload(file);
    };
  }

  private async validateAndProcessData(jsonData: any[]): Promise<boolean> {
    if (jsonData.length) {
      const firstObject = jsonData[0];
      if (Object.keys(firstObject).includes('__EMPTY')) {
        return false;
      }
      this.patchColumn(firstObject);
    }

    this.previewDataList = jsonData;
    this.pageSize = 50;
    this.currentPreviewPage = 1;
    this.totalPreviewPage = Math.ceil(this.previewDataList.length / this.pageSize);
    this.loadCurrentPageData();

    return true;
  }

  patchColumn(column: any) {
    for (const key of Object.keys(column)) {
      this.previewColumn.push({
        field: key,
        header: key,
        freezable: false,
        sortable: true,
        tableDefault: false,
        width: '14vw',
      });
    }
    return true
  }

  loadCurrentPageData() {
    const startIndex = (this.currentPreviewPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.displayDataList = this.previewDataList.slice(startIndex, endIndex);
  }

  async loadTinyMce() {
    await this.globalService.loadScript(
      'script',
      'https://cdn.tiny.cloud/1/r8fyjcvpxki8z6saolluylpci1u6mgcde4cm4rm3e1g8eiab/tinymce/5/tinymce.min.js',
      'text/javascript',
      'examlyck'
    );
  
    if (typeof tinymce !== 'undefined') {
      tinymce.remove();
      tinymce.init({
        selector: 'textarea#content',
        height: 500,
        width: '100%',
        menubar: false,
        toolbar_mode: 'wrap',
        resize: false,
        plugins: 'print preview paste searchreplace autolink directionality code visualblocks visualchars fullscreen link media codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount textpattern noneditable help charmap quickbars emoticons',
        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen preview print | insertfile quickimage media link anchor codesample | ltr rtl',
        toolbar_sticky: true,
        images_upload_url: '',
        image_caption: true,
        quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
        noneditable_noneditable_class: 'mceNonEditable',
        contextmenu: 'link image imagetools table',
        relative_urls: false,
        content_style:
          "@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap'); body { font-family: Poppins; }",
        setup: (editor: any) => {
          editor.on('Change KeyUp', () => {
            this.sendEmailForm.get('content')?.setValue(editor.getContent());
          });
  
          const fixDropdowns = () => {
            setTimeout(() => {
              document.querySelectorAll('.tox-tinymce-aux, .tox-menu, .tox-popover').forEach((el: Element) => {
                const htmlEl = el as HTMLElement;
                htmlEl.style.zIndex = '2147483647';
                htmlEl.style.position = 'fixed';
              });
                const sidebar = document.querySelector('.send-email.p-sidebar') as HTMLElement;
              if (sidebar) sidebar.style.overflow = 'visible';
            }, 0);
          };
  
          editor.on('FullscreenStateChanged', fixDropdowns);
          editor.on('ExecCommand', fixDropdowns);
          editor.on('OpenWindow', fixDropdowns);
          editor.on('click', fixDropdowns);
          editor.on('focus', fixDropdowns);
        }
      });
    }
  }
  
  onTemplateChange(event: any) {
    const selectedTemplateId = event.value;
    let data = this.templatesList.find((template: any) => template.id === selectedTemplateId);
    this.campaignType = data.template_title;
    this.clicked = false;
    this.sendEmailForm.controls['subjectTitle'].setValue(data.subject_title)
    this.sendEmailForm.controls['content'].setValue(data.template_content);
    tinymce.activeEditor.setContent(data.template_content);
  }

  ngOnDestroy() {
    this.sendEmailForm.reset();
  }
}