export enum Severity {
  ERROR = 'error',
  WARN = 'warn',
  SUCCESS = 'success',
  INFO = 'info'
}


export enum Summary {
  NETWORK = 'Network',
  SESSION = 'Session Expired',
  ERROR = 'Error',
  INFO = 'Info',
  VALIDATION = 'Validation',
  VALIDATIONERROR = 'Validation Error',
  SUCCESS = 'Success',
  INVALID = 'Invalid',
  CONFIRMED = 'Confirmed',
  REJECTED = 'Rejected',
  CANCELLED = 'Cancelled',
  FAILED = 'Failed',
  WARNING = 'Warning',
  UPLOADERR = 'Upload Error',
  EDITFIELDS = 'Enable Fields',
  UPDATEFAIL = 'Update Failed',
  CANNOTSAVE = 'Cannot Save',
  SELECTCAMPUS = 'Select Campus',
  EMPTYCOLUMN = 'Empty Column',
  PARTIALSUCCESS = 'Partial success',
  DOWNLOADINITIATED = 'Download Initiated',
  PARTIALLINK = 'Partial Link Sent',
  RESENDLINK = 'Cannot Resend Link',
  NOACTION = 'No Action',
  FILEMISSING = 'File Missing',
  UPLOADSUCCESS = "Upload Success",
  PARTIAL_MOVED = "Partial Moved"
}

export enum Message {
    DATA = 'Error retrieving data',
    OFFLINE = 'You are currently offline',
    FILESIZE = 'Uploaded file size exceeded',
    FILESIZE5MBLIMIT = 'Uploaded file size exceed 5MB limit',
    FILETYPEPDF = 'Only PDF files are allowed',
    UNCHANGED = 'No changes detected',
    NOCHANGES = 'No modifications made',
    MANDATORY = 'Please fill in all required fields',
    VALIDDEPT = 'Please enter valid department data',
    DETAILS = 'Details cleared successfully',
    REQUESTREJECT = 'Request has been rejected',
    REQUESTCANCEL = 'Request has been canceled',
    UPLOADIMGERR = 'Image upload failed',
    INVALIDEXCEL = 'Invalid Excel file',
    EMPTYEXCEL = 'The uploaded Excel file is empty!',
    SINGLECOLUMN = 'Cannot update with only one column',
    CAMPUSADDED = 'Campus has already been added',
    EMPTYCOLUMN = 'Empty column names found',
    CAMPUSERR = 'Error retrieving campus details',
    REQUIREDFIELDS = 'Please enter the required fields',
    ATLEASTONEFIELD = 'Please select at least one field',
    ATLEASTONESTUDENT = 'Select at least one student',
    SELECTALLFIELD = 'Select all fields to proceed',
    STAGEADDWARN = 'Few users cannot be added to the current stage due to eligiblity condition failed',
    ADDONESTUDENT = 'Add at least one student',
    STUDENTDETAILSUPDATEERR = 'Error updating student details',
    STUDENTDETAILSUPDATESUCCESS = 'Student details updated successfully',
    SELECTPROGRAMME = 'Please select at least one programme',
    EMAILUPDATE = "New email shouldn't be as old email",
    MOBILEUPDATE = "New mobile number shouldn't be same as old number",
    NOSTUDENTDATA = 'No student data available for download',
    NOREPORTDATA = 'No report data available for download',
    NOMETRICSDATA = 'No metrics data available for download',
    PROGRAMMEMAPERR = 'Error mapping programmes',
    INVALID_DOB = 'Invalid date of birth',
    FIELDMAP = 'To proceed, please enable fields in (Settings -> Student Fields) for the selected campus and passout year',
    BACKGROUNDPROCESS = 'Download is in progress. You will be notified when the download is complete.',
    BACKGROUNDPROCESSRESUME= 'Download Student Resumes is in progress. You will be notified when the download is complete.',
    CHANGEACTIONBACKGROUND = 'Change action is in progress. process will be completed in background',
    NOTINVITEDSTATE = "Selected students are not in the invited state. Resending will only apply to invited students.",
    DRIVEHOLD = 'Drive is on hold. Please resume the drive to move students.',
    DRIVEINSAVED = 'Drive is in saved state. Please publish the drive to add students.',
    MOVESTUDENTS = 'Drive is completed. Students cannot be moved now.',
    PROGRAMMEOFFERED = 'Please select the Programme Offered for each criterion',
    ACTIVATEFIELD = 'Select at least one field to activate',
    FETCHPROGRAMMEERR = 'Error retrieving programme mapping data',
    PROFILEUPDATE = 'Profile updated successfully',
    DRIVEUPDATE = 'Drive updated successfully',
    CAMPUSANDADMISSIONYEAR = 'Please select the campus and admission year before specifying the passout year.',
    FIELDREMOVED = 'Field removed successfully, kindly save to proceed with the changes',
    FIELDREMOVALWARN = 'Please be aware that removing this field will delete the data for all mapped students. Proceed carefully.',
    ENTERSALARY = 'Please fill in salary or stipend field',
    LABELLENGTH = 'Field label must be less than 100 characters',
    FETCHSTUDENTERR = 'Error retrieving student data',
    STUDENTINFOERR = 'Error retrieving student information',
    PERFORMACTION = 'Error performing action',
    OFFEREDSALARY = 'Please enter the offered salary',
    OFFEREDSTIPEND = 'Please enter the offered stipend',
    DRIVESTATUS = 'Drive status updated successfully',
    EMAILCHANGE = 'Email changed successfully',
    MOVESTUDENTSPROFILE = 'Select a profile to move students',
    DRIVEEND = 'Drive ended successfully',
    OFFERTYPESET = 'Offer type set successfully',
    OFFERSETTINGERR = 'Error setting the offer type',
    DELETESUCCESS = 'Deleted successfully',
    PROGRAMMEDELETEERR = 'Error deleting programmes',
    GROUPINGREMOVED = 'Program Grouping removed successfully',
    REMOVEGROUPINGERR = 'Error removing Program Grouping',
    SELECTGROUPING = 'Please select a Program Grouping',
    INVALIDFILETYPE = 'Invalid file type',
    UPLOADFILE = 'Please upload a file',
    NOEMAILS = 'No email address is found to set offer type',
    STUDENTOFFERTYPE = 'Some students had their offer type set successfully',
    MAPGROUPINGSUCCESS = 'Program Grouping mapped successfully',
    MAPGROUPINGERR = 'Error mapping Program Grouping',
    MAXFILES = 'Maximum number of files reached',
    FILEEXCEED = 'File size must not exceed 5MB',
    REJECTED = 'You have rejected the request',
    DUPLICATEFIELDS = 'Duplicate field names detected',
    FILELARGE = 'File is too large',
    TEMPLATEEDIT = 'Template edited successfully',
    DRIVEINFOFETCHERR = 'Error retrieving drive information',
    VALIDFIELDLABEL = 'Please enter a valid field label',
    DRIVECHANGEERR = 'Error changing drive status',
    SELECTFIELDSDWNLD = 'Please select fields to download',
    STUDENTSSELECT = 'Please select the students',
    UNABELTEMPLATEEDIT = 'Unable to edit template',
    TEMPLATESUCCESS = 'Template created successfully',
    ERRUPLOADINGFILE = 'Error uploading file',
    TEMPLATEDELETESUCCESS = 'Template deleted successfully',
    TEMPLATEDELETEERR = 'Error deleting template',
    DRIVEDWNLDERR = 'Error downloading drives',
    DATADWNLDERR = 'Error downloading data',
    DRIVEFETCHERR = 'Error retrieving drives',
    DRIVECLONESELECT = 'Please select only one drive to clone',
    DRIVECLONEERR = 'Error cloning drive',
    DELETEDRIVEERR = 'Error deleting drive',
    DELETESTUDENTERR = 'Error deleting students',
    FILTERSAVE = 'Table filters saved successfully',
    FILTERSAVEERR = 'Error saving filters',
    TEMPLATESAVEERR = 'Error saving template',
    TEMPLATECREATE = 'Template created successfully',
    DRIVEPUBLISH = 'Drive published successfully',
    DRIVEPUBLISHERR = 'Error publishing drive',
    DRIVEUPDATEERR = 'Error updating drive',
    FIELDMAPERR = 'Error mapping fields, please try again later',
    CUSTOMPSWRDERR = 'Error setting custom password',
    DRIVECREATED = 'Drive created successfully',
    DRIVECREATEERR = 'Error creating drive',
    EDITEMAILDENY = 'Editing email is disabled',
    BACKGROUNDPROCESSSTARTED = "Background process has started, you'll be notified when the process is complete.",
    FETCHSTUDENTSERR = 'Error retrieving students',
    DRIVECOMPLETE = 'Drive is completed. Students cannot be added now.',
    DRIVECOMPLETERESTRICT = 'The drive is in a completed or on-hold state; this action is not allowed',
    FIELDUPDATESUCCESS = 'Fields updated successfully',
    RESENDPASSWORD = 'Password has been successfully reset to the default password',
    CUSTOMPASSCONFIRM = 'Custom password change request initiated. You will be notified upon completion.',
    LINKNOTSET = 'Link will be sent to selected students in the background',
    RESENDING = 'Some students are not invited. Resending will proceed for invited students only.',
    FIELDUPDATEERR = 'Error updating fields',
    TEMPLATESAVE = 'Template saved successfully',
    ERROROCCURED = 'An error occurred',
    ENTERREASON = 'Please provide a reason',
    FILTERERR = 'Error retrieving filter',
    CHECKERRDATA = 'Please fix the errors in the below table, to proceed',
    CUSTOMFILTERWARN = 'Please complete the previously added custom filter',
    PLACEMENTFILTER = 'Please complete the previously added placement filter',
    VALIDEMAIL = 'Please add a valid email address',
    PUSHDISABLED = 'Push notifications are currently disabled in platform settings. Kindly enable the same to use it.',
    MAILDISABLED = 'Email notifications are currently disabled in platform settings. Kindly enable the same to use it.',
    EXISTINGEMAIL = 'Some emails are not registered, messages cannot be sent.',
    INVALIDWPEMAIL = 'Some users have either not provided a mobile number or have not verified their mobile number yet.',
    EMAILSVALID = 'Emails validated successfully.',
    STUDENTINVITEERR = 'Error inviting students',
    DUPLICATEMAILS = 'Duplicate email addresses detected. Ensure all emails are unique.',
    PROFILEUPDATEFAILED = 'Updation Failed',
    COLLEGEPROFILEUPDATE = 'College Profile Updated Successfully',
    ADMISSIONERROR = 'Error in fetching admissions',
    CHANGEREQUESTERROR = 'Error in fetching change request student details',
    CHANGEREQUESTDONE = 'Change request done successfully',
    CHANGEREQUESTAPPROVEERR = 'Error while approving the request!',
    CHANGEREQUESTUPDATEERR = 'Error while updating the change request',
    CHANGEREQUESTSENDERR = 'Error while sending the change request data',
    VALIDFIRSTNAME = 'Kindly enter valid data in first name field',
    VALIDLASTNAME = 'Kindly enter valid data in last name field',
    DRIVEELIGIBLE = 'Drive date has been changed. Drive added in republish Queue, Newly eligible students will now eligible for this drive.',
    CRITERIASAVED = 'Criteria saved successfully',
    CRITERIASAVEERR = 'Error in saving criteria',
    WPSETTINGSUCCESS = 'Whatsapp settings updated successfully',
    WPSETTINGERR = 'Error updating whatsapp settings',
    EMPTYFIELD = 'Please fill the fields',
    MESSAGESUCCESS = 'Message sent successfully!',
    MESSAGEERR = 'Error sending Message!',
    SELECTSTUDENTS = 'Select students to send message',
    ENTEREMAILREG = 'Please enter Email or Registration Number',
    DRIVEDELETERESTRICT = 'Please select only one drive to delete',
    DRIVEDELETESTUDENTPLACEDONDRIVE = 'Some students are placed in this drive. Please remove them before deleting the drive',
    ACTIVATEONE = 'Atleast one Template should be active',
    ROUNDCREATIONERROR = 'Please complete the previous round creation',
    EVENTSAVEERROR = 'Error Saving the Round',
    EVENTSAVESUCCESS = 'Event Saved Successfully',
    EVENTEDITSUCCESS = 'Event Edited Successfully',
    DRIVESELECTERROR = 'Please select a drive',
    EVENTDELETEERROR = 'Error Deleting the Event',
    EVENTDELETESUCCESS = 'Event Deleted Successfully',
    ROUNDELETESUCCESS = 'Round Deleted Successfully',
    DELETEEVENT = 'Are you sure you want to delete this round? if the round is deleted, round order will be changed.',
    ERRORFETCHINGEVENT = 'Error fetching events',
    EVENTNAMEERROR = 'Please enter the event name',
    COMPANYFETCHERR = 'Error fetching company details',
    DRIVEREMINDERERROR = 'Error sending drive reminder',
    DRIVEROUNDREMAINDSEND = 'Drive reminder sent successfully to no action performed students',
    DRIVEREMINDERSUCCESS = 'Drive round reminder sent successfully to opted-in students',
    DRIVEREMINDERWARNING = 'Reminders can only be sent every 4 hours. Please wait.',
    PROGRAMGROUPING = 'Group name already exists',
    EVENTNOCHANGES = 'No changes detected',
    ROUNDELETERROR = 'Error deleting the round',
    MAXROWS = 'A maximum of 2000 rows can be imported at once.',
    PLACEMENTPOLICYUPDATE = 'Placement policy updated successfully',
    PLACEMENTPOLICYUPDATEERROR = 'Error updating placement policy',
    PLACEMENTPOLICYFIELDWARN = 'Please fill in all required fields',
}
